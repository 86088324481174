import { rem } from "polished";
import React, { PropsWithChildren, useMemo } from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

import { InputContextText } from "emoreg/atoms/Inputs/InputContextText";
import { StaticInputProps } from "emoreg/atoms/Inputs/types";
import { color, font } from "emoreg/const";
import { Flex } from "emoreg";

const LabelForCheckedInputStyle = styled.span`
  flex: 1;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  color: currentColor;
  ${ifProp(
    "isActive",
    css`
      font-weight: ${font.weight.semiBold};
    `
  )}
  ${ifProp(
    "disabled",
    css`
      color: ${color.grey.dark};
    `
  )}
`;

const SubLabelStyle = styled.span`
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  color: ${color.grey.dark};
`;

type LabelForCheckedInputProps = PropsWithChildren<{
  isActive?: boolean;
  disabled?: boolean;
}>;

export const LabelForCheckedInput = ({
  disabled,
  isActive,
  children,
}: LabelForCheckedInputProps) => (
  <LabelForCheckedInputStyle isActive={isActive} disabled={disabled}>
    {children}
  </LabelForCheckedInputStyle>
);
export const SubLabel = (props: LabelForCheckedInputProps) => (
  <SubLabelStyle>{props.children}</SubLabelStyle>
);

export const CommonInputish = ({
  errorText,
  warningText,
  helpText,
  children,
  childrenWrapperComponent,
}: PropsWithChildren<StaticInputProps> & {
  childrenWrapperComponent?: React.ComponentType<PropsWithChildren<{}>>;
}) => {
  const DefaultWrapper = useMemo(
    () => childrenWrapperComponent || (({ children }) => <>{children}</>),
    [childrenWrapperComponent]
  );
  return (
    <Flex direction="column" gap={8}>
      <DefaultWrapper>{children}</DefaultWrapper>
      {helpText && <InputContextText type={"help"}>{helpText}</InputContextText>}
      {errorText && <InputContextText type={"error"}>{errorText}</InputContextText>}
      {warningText && <InputContextText type={"warning"}>{warningText}</InputContextText>}
    </Flex>
  );
};

const InputIconWrapper = styled.span`
  position: absolute;
  // 14px is the SVG box size when asking for an Icon of size 12px.
  width: 14px;
  height: 14px;

  ${(props: any) => css`
    ${props.iconPosition}: 12px; // 12px is the input outer left padding
  `}

  top: 50%;
  margin-top: -7px; // React-Icons SVG is 14px

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CommonInputishPossiblyWithIcon = (
  props: PropsWithChildren<StaticInputProps> & {
    iconComponent?: () => JSX.Element;
    iconPosition?: string;
  }
) => {
  const iconUnWrapped = props.iconComponent?.();
  const clonedIcon = iconUnWrapped
    ? React.cloneElement(iconUnWrapped, {
        color: iconUnWrapped.props.color || color.grey.dark,
        size: "12px",
      })
    : null;

  return (
    <CommonInputish {...props}>
      <span style={{ position: "relative" }}>
        {clonedIcon ? (
          <InputIconWrapper iconPosition={props.iconPosition || "left"}>
            {clonedIcon}
          </InputIconWrapper>
        ) : null}
        {props.children}
      </span>
    </CommonInputish>
  );
};
