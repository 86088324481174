/**
 * FIXME: Écrire les types conditionnels entre "vrai bouton" (avec
 * onClick) et "faux bouton" (les "a" avec "href").
 *
 * Supprimer la logique de loading/ok/error sur les faux boutons.
 */
import { darken } from "polished";
import React, { PropsWithChildren, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ifNotProp, ifProp } from "styled-tools";

import { Icon } from "emoreg/atoms/Icon";
import { InlineLoader } from "emoreg/atoms/Loader";
import { color, font, radius, spaces } from "emoreg/const";
import { switchProp } from "emoreg/utils/switch-prop";

type ButtonColors = "blue" | "red" | "grey" | "green" | "none";
type ButtonSizes = "medium" | "small" | "mini";
type ButtonStates = "base" | "loading" | "ok" | "error";
type IconPositions = "before" | "after";

const ButtonColorsToRealColor = {
  blue: { main: color.blue.base, outlineBorder: color.blue.base },
  red: { main: color.red.base, outlineBorder: color.red.base },
  grey: { main: color.grey.dark, outlineBorder: color.grey.base },
  green: { main: color.green.base, outlineBorder: color.green.base },
  none: { main: "#ffffff00", outlineBorder: "#ffffff00" },
};

type RawButtonStyleProps = {
  backgroundColor: string;
  borderColor: string;
  readOnly: boolean;
  textColor: string;
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  hoverTextColor: string;
  size: ButtonSizes;
  fullWidth: boolean;
  outline: boolean;
  disabled: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>;
  href?: string;
  iconPosition: IconPositions;
  as: string;
};

const RawButtonStyle: React.FC<PropsWithChildren<RawButtonStyleProps>> = styled.button`
  /* Reset stuff */
  appearance: none;
  text-decoration: none;
  &:focus {
    outline: none;
  }

  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-direction: ${switchProp<RawButtonStyleProps, "iconPosition">("iconPosition", {
    before: "row",
    after: "row-reverse",
  })};
  align-items: center;
  border-radius: ${radius.base};
  font-weight: ${font.weight.semiBold};

  // Sizes are "hardcoded", 6px is not a known size.
  gap: ${switchProp<RawButtonStyleProps, "size">("size", {
    medium: "8px",
    small: "6px",
    mini: "4px",
  })};

  background-color: ${props => props.backgroundColor};
  border: 0.5px solid ${props => props.borderColor};
  color: ${props => props.textColor};

  ${props => {
    if (props.readOnly) {
      return css`
        pointer-events: none;
        cursor: default;
      `;
    }
    if (props.disabled) {
      return css`
        cursor: not-allowed;
      `;
    } else {
      return css`
        cursor: pointer;
        &:hover {
          text-decoration: none;
          background-color: ${props => props.hoverBackgroundColor};
          border: 0.5px solid ${props => props.hoverBorderColor};
          color: ${props => props.hoverTextColor};
        }
      `;
    }
  }}

  ${ifProp("fullWidth", "width: 100%;", "")}
  ${ifProp("disabled", "opacity: 0.4;", "")}
  ${ifNotProp("disabled", "cursor: pointer;", "")}

   height: ${switchProp<RawButtonStyleProps, "size">("size", {
    medium: spaces[48],
    small: spaces[32],
    mini: spaces[24],
  })};

  padding: ${switchProp<RawButtonStyleProps, "size">("size", {
    medium: spaces[16],
    small: `${spaces[8]} ${spaces[12]}`,
    mini: `${spaces[4]} ${spaces[8]}`,
  })};

  font-size: ${switchProp<RawButtonStyleProps, "size">("size", {
    medium: "16px",
    small: "14px",
    mini: "12px",
  })};
`;

const RawInlineStatus = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InlineStatus = ({ state }: { state: ButtonStates }) => {
  let content;
  if (state === "loading") {
    content = <InlineLoader />;
  } else if (state === "ok") {
    content = <Icon name="checkmark" />;
  } else if (state === "error") {
    content = <Icon name="close" />;
  }
  return <RawInlineStatus>{content}</RawInlineStatus>;
};

type RawButtonProps = {
  color: ButtonColors;
  size: ButtonSizes;
  fullWidth: boolean;
  outline: boolean;
  disabled: boolean;
  readOnly: boolean;
  state: ButtonStates;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>;
  href?: string;
  iconComponent?: () => JSX.Element;
  iconPosition: IconPositions;
};

const computeInnerStyles = (color, outline) => {
  if (outline) {
    return {
      backgroundColor: "transparent",
      borderColor: ButtonColorsToRealColor[color]["outlineBorder"],
      textColor: ButtonColorsToRealColor[color]["main"],
      hoverBackgroundColor: ButtonColorsToRealColor[color]["main"],
      hoverBorderColor: ButtonColorsToRealColor[color]["main"],
      hoverTextColor: "white",
    };
  } else {
    return {
      backgroundColor: ButtonColorsToRealColor[color]["main"],
      borderColor: ButtonColorsToRealColor[color]["main"],
      textColor: "white",
      hoverBackgroundColor: darken(0.1)(ButtonColorsToRealColor[color]["main"]),
      hoverBorderColor: darken(0.1)(ButtonColorsToRealColor[color]["main"]),
      hoverTextColor: "white",
    };
  }
};

export const RawButton = (props: PropsWithChildren<RawButtonProps>) => {
  const stateIsNotBase = props.state !== "base";
  const disabled = props.disabled || stateIsNotBase;
  const {
    backgroundColor,
    borderColor,
    textColor,
    hoverBackgroundColor,
    hoverBorderColor,
    hoverTextColor,
  } = computeInnerStyles(props.color, props.outline);
  return (
    <RawButtonStyle
      fullWidth={props.fullWidth}
      disabled={disabled}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverBorderColor={hoverBorderColor}
      hoverTextColor={hoverTextColor}
      onClick={props.onClick}
      href={props.href}
      outline={props.outline}
      size={props.size}
      iconPosition={props.iconPosition}
      readOnly={props.readOnly}
      as={props.href ? "a" : "button"}
    >
      {props.iconComponent ? (
        <span style={{ visibility: stateIsNotBase ? "hidden" : "visible", display: "contents" }}>
          {props.iconComponent?.()}
        </span>
      ) : null}
      {React.Children.toArray(props.children).filter(c => !!c).length > 0 ? (
        <span style={{ visibility: stateIsNotBase ? "hidden" : "visible" }}>{props.children}</span>
      ) : (
        props.children
      )}
      {stateIsNotBase && <InlineStatus state={props.state} />}
    </RawButtonStyle>
  );
};

type ButtonProps = {
  color: ButtonColors;
  size?: ButtonSizes;
  fullWidth?: boolean;
  outline?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any> | any;
  // This is an escape hatch to avoid the 1500ms delay and state change
  // for sync buttons.
  onClickSyncBehavior?: boolean;
  // This is an escape hatch to avoid changing the button state if a
  // success triggers an unmount.
  onClickSuccessTriggerUnmount?: boolean;
  href?: string;
  iconComponent?: () => JSX.Element;
  iconPosition?: IconPositions;
};

export const Button = ({
  color,
  size,
  outline,
  disabled,
  onClick,
  onClickSyncBehavior,
  onClickSuccessTriggerUnmount,
  href,
  iconComponent,
  readOnly,
  iconPosition,
  fullWidth = false,
  children,
}: PropsWithChildren<ButtonProps>): JSX.Element => {
  // FIXME: throw if both or neither href/onClick
  const [buttonState, setButtonState] = useState<ButtonStates>("base");

  const promisifiedOnClick = useMemo(() => {
    if (onClick) {
      // If we "escape hatched" the normal cycle we just return the
      // given `onClick`, wrapped in a resolve.
      if (onClickSyncBehavior) {
        return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          return Promise.resolve(onClick(e));
        };
      }

      // Otherwise we wrap the given `onClick` into an animation cycle
      else {
        return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          setButtonState("loading");
          return Promise.resolve(onClick(e)).then(
            () => {
              if (!onClickSuccessTriggerUnmount) {
                setButtonState("ok");
                setTimeout(() => {
                  setButtonState("base");
                }, 1500);
              }
            },
            () => {
              setButtonState("error");
              setTimeout(() => {
                setButtonState("base");
              }, 1500);
            }
          );
        };
      }
    }

    return undefined;
  }, [onClick, onClickSyncBehavior, onClickSuccessTriggerUnmount]);
  return (
    <RawButton
      fullWidth={fullWidth}
      color={color}
      size={size || "medium"}
      outline={!!outline}
      disabled={disabled || buttonState === "loading" || false}
      state={buttonState}
      onClick={promisifiedOnClick}
      href={href}
      iconComponent={iconComponent}
      iconPosition={iconPosition || "before"}
      readOnly={readOnly || false}
    >
      {children}
    </RawButton>
  );
};
