/**
 * THIS MODULE IS GENERATED: DO NOT EDIT MANUALLY.
 *
 * Cf `src/hassibot/permer/README.md` and `src/hassibot/permer/requirements-autogen-maker`.
 */
import { has_requirement } from "@ouihelp/permer_web";

const PERMER_NO_ATTRIBUTES = "no-attribute";

const hasRequirement = (
  requirement: string,
  serializedSecurityPolicy: any,
  serializedUserAttributes: string[]
): boolean => {
  return has_requirement(
    requirement,
    JSON.stringify(serializedSecurityPolicy),
    JSON.stringify(serializedUserAttributes)
  );
};

export type PermerRequirements = {
  hasAgencyCreate: () => boolean;
  hasAgencyUpdate: () => boolean;
  hasCaregiverBroadcastCreate: () => boolean;
  hasCollaboratorAllNotifications: () => boolean;
  hasCompassAdmin: () => boolean;
  hasMonetBillCreateAdhoc: () => boolean;
  hasMonetBillUpdate: (agency?: string | null) => boolean;
  hasMonetPaymentUpdate: (agency?: string | null) => boolean;
  hasMonetRegularizationComment: (agency?: string | null) => boolean;
  hasMonetRegularizationUpdateStatus: (agency?: string | null) => boolean;
  hasMonetSalesLogRead: () => boolean;
  hasMonetThirdPartyPayerCreate: () => boolean;
  hasCaregiverTestAppUpdate: () => boolean;
  hasCaregiverAdmin: () => boolean;
  hasCaregiverHappypalAccountCreate: () => boolean;
  hasPrescriberAnimatedByUpdate: () => boolean;
  hasPrescriberOwnerUpdate: () => boolean;
  hasProclientCallEvaluationUpdate: () => boolean;
  hasProclientCallEvaluationRead: () => boolean;
  hasProclientCallEvaluationList: () => boolean;
  hasProclientCallEvaluationSalesOwnerAssign: () => boolean;
  hasProclientCallEvaluationSalesOwnerUpdate: () => boolean;
  hasProclientPricingStrategyUpdateNoLimit: () => boolean;
  hasProclientSalesOwnerUpdate: () => boolean;
  hasProclientIssueClose: (agency?: string | null) => boolean;
  hasProclientIssueCreate: (agency?: string | null) => boolean;
  hasProclientBiweeklyPaymentUpdate: (agency?: string | null) => boolean;
  hasProclientWeeklyHoursSegmentation: () => boolean;
  hasProclientAdmin: (agency?: string | null) => boolean;
  hasProclientLeadOwnerUpdate: () => boolean;
  hasProclientPicoBypass: () => boolean;
  hasProclientFilterAnyOwner: () => boolean;
  hasPlaceSetManual: () => boolean;
  hasStructureAnimatedByUpdate: () => boolean;
  hasStructureOwnerUpdate: () => boolean;
  hasZoneCreate: () => boolean;
  hasZoneUpdate: () => boolean;
  hasEventDeleteVebAfter: () => boolean;
  hasEventDeleteVebBeforePlannedByIs: () => boolean;
  hasEventDeleteVebBeforePlannedByBd: () => boolean;
  hasEventDeleteVebBeforePlannedByOps: () => boolean;
};

export const makePermerRequirements = (userAttributes, securityPolicy): PermerRequirements => {
  return {
    hasAgencyCreate: () => hasRequirement(`agency:create`, securityPolicy, userAttributes),
    hasAgencyUpdate: () => hasRequirement(`agency:update`, securityPolicy, userAttributes),
    hasCaregiverBroadcastCreate: () =>
      hasRequirement(`caregiver:broadcast:create`, securityPolicy, userAttributes),
    hasCollaboratorAllNotifications: () =>
      hasRequirement(`collaborator:all-notifications`, securityPolicy, userAttributes),
    hasCompassAdmin: () => hasRequirement(`compass:admin`, securityPolicy, userAttributes),
    hasMonetBillCreateAdhoc: () =>
      hasRequirement(`monet:bill:create-adhoc`, securityPolicy, userAttributes),
    hasMonetBillUpdate: (agency?: string | null) =>
      hasRequirement(
        `monet:bill:update:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasMonetPaymentUpdate: (agency?: string | null) =>
      hasRequirement(
        `monet:payment:update:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasMonetRegularizationComment: (agency?: string | null) =>
      hasRequirement(
        `monet:regularization:comment:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasMonetRegularizationUpdateStatus: (agency?: string | null) =>
      hasRequirement(
        `monet:regularization:update-status:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasMonetSalesLogRead: () =>
      hasRequirement(`monet:sales-log:read`, securityPolicy, userAttributes),
    hasMonetThirdPartyPayerCreate: () =>
      hasRequirement(`monet:third-party-payer:create`, securityPolicy, userAttributes),
    hasCaregiverTestAppUpdate: () =>
      hasRequirement(`caregiver:test-app:update`, securityPolicy, userAttributes),
    hasCaregiverAdmin: () => hasRequirement(`caregiver:admin`, securityPolicy, userAttributes),
    hasCaregiverHappypalAccountCreate: () =>
      hasRequirement(`caregiver:happypal-account:create`, securityPolicy, userAttributes),
    hasPrescriberAnimatedByUpdate: () =>
      hasRequirement(`prescriber:animated-by:update`, securityPolicy, userAttributes),
    hasPrescriberOwnerUpdate: () =>
      hasRequirement(`prescriber:owner:update`, securityPolicy, userAttributes),
    hasProclientCallEvaluationUpdate: () =>
      hasRequirement(`proclient:call-evaluation:update`, securityPolicy, userAttributes),
    hasProclientCallEvaluationRead: () =>
      hasRequirement(`proclient:call-evaluation:read`, securityPolicy, userAttributes),
    hasProclientCallEvaluationList: () =>
      hasRequirement(`proclient:call-evaluation:list`, securityPolicy, userAttributes),
    hasProclientCallEvaluationSalesOwnerAssign: () =>
      hasRequirement(
        `proclient:call-evaluation:sales-owner:assign`,
        securityPolicy,
        userAttributes
      ),
    hasProclientCallEvaluationSalesOwnerUpdate: () =>
      hasRequirement(
        `proclient:call-evaluation:sales-owner:update`,
        securityPolicy,
        userAttributes
      ),
    hasProclientPricingStrategyUpdateNoLimit: () =>
      hasRequirement(`proclient:pricing-strategy:update:no-limit`, securityPolicy, userAttributes),
    hasProclientSalesOwnerUpdate: () =>
      hasRequirement(`proclient:sales-owner:update`, securityPolicy, userAttributes),
    hasProclientIssueClose: (agency?: string | null) =>
      hasRequirement(
        `proclient:issue:close:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasProclientIssueCreate: (agency?: string | null) =>
      hasRequirement(
        `proclient:issue:create:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasProclientBiweeklyPaymentUpdate: (agency?: string | null) =>
      hasRequirement(
        `proclient:biweekly-payment:update:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasProclientWeeklyHoursSegmentation: () =>
      hasRequirement(`proclient:weekly-hours-segmentation`, securityPolicy, userAttributes),
    hasProclientAdmin: (agency?: string | null) =>
      hasRequirement(
        `proclient:admin:${agency ?? PERMER_NO_ATTRIBUTES}`,
        securityPolicy,
        userAttributes
      ),
    hasProclientLeadOwnerUpdate: () =>
      hasRequirement(`proclient:lead-owner:update`, securityPolicy, userAttributes),
    hasProclientPicoBypass: () =>
      hasRequirement(`proclient:pico:bypass`, securityPolicy, userAttributes),
    hasProclientFilterAnyOwner: () =>
      hasRequirement(`proclient:filter-any-owner`, securityPolicy, userAttributes),
    hasPlaceSetManual: () => hasRequirement(`place:set-manual`, securityPolicy, userAttributes),
    hasStructureAnimatedByUpdate: () =>
      hasRequirement(`structure:animated-by:update`, securityPolicy, userAttributes),
    hasStructureOwnerUpdate: () =>
      hasRequirement(`structure:owner:update`, securityPolicy, userAttributes),
    hasZoneCreate: () => hasRequirement(`zone:create`, securityPolicy, userAttributes),
    hasZoneUpdate: () => hasRequirement(`zone:update`, securityPolicy, userAttributes),
    hasEventDeleteVebAfter: () =>
      hasRequirement(`event:delete-veb:after`, securityPolicy, userAttributes),
    hasEventDeleteVebBeforePlannedByIs: () =>
      hasRequirement(`event:delete-veb:before:planned-by-is`, securityPolicy, userAttributes),
    hasEventDeleteVebBeforePlannedByBd: () =>
      hasRequirement(`event:delete-veb:before:planned-by-bd`, securityPolicy, userAttributes),
    hasEventDeleteVebBeforePlannedByOps: () =>
      hasRequirement(`event:delete-veb:before:planned-by-ops`, securityPolicy, userAttributes),
  };
};
