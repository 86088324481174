import styled from "styled-components";
import { Label } from "styles/atoms";
import { rem } from "polished";
import { spaces } from "emoreg/const";
import { responsiveHelpers as rh } from "styles/utils";

const DataFieldStyle = styled.div`
  & > div {
    display: flex;
    flex-direction: ${props => (props.column ? "column" : "row")};
    align-items: ${props => (props.column ? "start" : "center")};
    font-size: ${rem(14)};
    white-space: pre-line;
    ${rh.belowPortraitTablet`
      justify-content: ${props => (props.centeredMobile ? "center" : "flex-start")}
    `}
  }
  & ul {
    margin-bottom: 0;
  }
  &:not(:last-child) {
    ${rh.belowPortraitTablet`
      margin-bottom: ${spaces[16]};
    `}
  }
`;

type Props = {
  title?: string;
  noDataText?: string;
  className?: string;
  centeredMobile?: boolean;
};

export const DataField = ({
  title,
  noDataText = "Non renseigné(e)",
  children,
  className,
  centeredMobile = false,
}: React.PropsWithChildren<Props>) => {
  return (
    <DataFieldStyle centeredMobile={centeredMobile} className={className || ""}>
      {title && <Label>{title}</Label>}
      <div>{children || <i>{noDataText}</i>}</div>
    </DataFieldStyle>
  );
};
