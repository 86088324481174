import { useEffect, useRef } from "react";
import { CheckboxInputStyle } from "./styles";
import React from "react";
import mergeRefs from "merge-refs";

export type CheckboxInputProps = {
  id: string;
  checked?: boolean | null;
  indeterminate?: boolean | null;
  onChangeValue?: (value: boolean) => void;
  hasError?: boolean;
  subLabel?: string;
} & Omit<React.HTMLProps<HTMLInputElement>, "id" | "checked" | "ref" | "label">;

export const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ indeterminate, onChange, onChangeValue, className, style, hasError, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (inputRef.current) inputRef.current.indeterminate = !!indeterminate;
    }, [inputRef, indeterminate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      onChangeValue?.(event.target.checked);
    };

    return (
      <CheckboxInputStyle hasError={hasError} className={className} style={style}>
        <CheckboxInputStyle.Input
          ref={mergeRefs(inputRef, ref)}
          type="checkbox"
          onChange={handleChange}
          {...props}
        />
      </CheckboxInputStyle>
    );
  }
);
