import { css } from "styled-components";
import { spaces, color, font } from "emoreg/const";
import { rem } from "polished";
import { BaseNavigation, NavigationWithRouterItem, NavItem, RouterNavigation } from "./nav";

const NAV_VERTICAL_STYLE = css`
  display: flex;
  flex-direction: column;
`;
const ITEM_VERTICAL_STYLE = css`
  font-size: ${rem(14)};
  margin-bottom: ${spaces[8]};
  cursor: pointer;
  &:hover {
    color: ${color.grey.darker};
    text-decoration: none;
  }
`;
const ITEM_VERTICAL_STYLE_ACTIVE = css`
  ${({ isActive }: { isActive: boolean }) =>
    isActive
      ? css`
          font-weight: ${font.weight.semiBold};
          color: ${color.grey.darker};
          &:hover {
            color: ${color.grey.darker};
          }
        `
      : null}
`;

export const VerticalNavigation = ({ ...props }: { navItems: NavItem[] }) => (
  <BaseNavigation
    listStyle={NAV_VERTICAL_STYLE}
    itemStyle={ITEM_VERTICAL_STYLE}
    activeItemStyle={ITEM_VERTICAL_STYLE_ACTIVE}
    {...props}
  />
);

export const VerticalRouterNavigation = ({
  ...props
}: {
  navItems: NavigationWithRouterItem[];
  noMatchRedirectTo?: string;
}) => (
  <RouterNavigation
    listStyle={NAV_VERTICAL_STYLE}
    itemStyle={ITEM_VERTICAL_STYLE}
    activeItemStyle={ITEM_VERTICAL_STYLE_ACTIVE}
    {...props}
  />
);
