import { DetailedReport } from "hassibot/component/events/types";
import {
  Caregiver,
  CaregiverDiplomaMap,
  CaregiverLanguageMap,
  CaregiverSkillMap,
  CaregiverTrainingDroppedReasonsMap,
  JobsSpecialities,
  PrescriberJobs,
  StructureTypeMap,
} from "hassibot/services_v2/caregiver/types";
import {
  BaseDebriefedEventContacts,
  CallTypeExtended,
  EventDirection,
  EventTypes,
  VisitTypeExtended,
} from "hassibot/services_v2/event/types";
import { Prescriber } from "hassibot/services_v2/prescriber/types";
import { Proclient, ProclientIssueType } from "hassibot/services_v2/proclient/types";
import { PackageFromIDB, ServiceFromIDB } from "hassibot/services_v2/service/types";
import { DateTime } from "luxon";
import { OhApi } from "siayuda";
import { Assertion } from "../assertion/types";
import { GenerableDocument } from "../document/types";
import { FinancialHelp } from "../dsit/types";
import { MonetPricingStrategyTemplate } from "../monet/bills/types/pricing-strategy";

export type JsonValue = any; // eslint-disable-line

export type Router<T> = { [K in keyof T]: T[K] };

// Business types
export type Phone = TypeBuilder<string, "phone">;
export type Gender = "male" | "female" | "other";
export type Mail = TypeBuilder<string, "mail">;

export enum GenderEnum {
  male = "male",
  female = "female",
  other = "other",
}

export type Position = {
  latitude: number;
  longitude: number;
};

export type Pager = {
  page?: number;
  size: number;
};
type SortOrder = "asc" | "desc";
export type Sort = {
  field: string;
  order: SortOrder;
};
export type Filters = Partial<{ [k: string]: string | number | boolean | DateTime }>;

// See Flavor typing https://spin.atomicobject.com/2018/01/15/typescript-flexible-nominal-typing/
interface Typing<T> {
  _type?: T;
}
type TypeBuilder<U, T> = U & Typing<T>;

export type UuidTypeBuilder<T> = TypeBuilder<string, T>;

export type PicoBadgeUuid = UuidTypeBuilder<"badge_uuid">;
export type PicoCodeUuid = UuidTypeBuilder<"pico_code_uuid">;
export type CaregiverUuid = UuidTypeBuilder<"caregiver">;
export type BlacklistCaregiverProclientUuid = UuidTypeBuilder<"blacklist_caregiver_proclient">;
export type InterventionToStaffPropositionUuid = UuidTypeBuilder<"intervention_to_staff_uuid">;
export type CaregiverCandidateUuid = UuidTypeBuilder<"caregiver_candidate">;
export type CaregiverCandidateApplicationUuid = UuidTypeBuilder<"caregiver_candidate_application">;
export type CaregiverInterventionAbsenceUuid = UuidTypeBuilder<"caregiver_intervention_absence">;
export type BroadcastUuid = UuidTypeBuilder<"broadcast">;
export type ContractUuid = UuidTypeBuilder<"contract">;
export type AuditLogUuid = UuidTypeBuilder<"audit_log">;
export type DebriefedEventLogUuid = UuidTypeBuilder<"debriefed_event_log">;
export type ProclientUuid = UuidTypeBuilder<"proclient">;
export type CustomerServiceTicketUuid = UuidTypeBuilder<"customer_service_ticket">;
export type CallEvaluationUuid = UuidTypeBuilder<"call_evaluation">;
export type VebEvaluationUuid = UuidTypeBuilder<"veb_evaluation">;
export type MonetBillUuid = UuidTypeBuilder<"monet_bill_id">;
export type MonetPricingStrategyUuid = UuidTypeBuilder<"monet_pricing_strategy_uuid">;
export type MonetPricingStrategyTemplateUuid =
  UuidTypeBuilder<"monet_pricing_strategy_template_uuid">;
export type ProclientMemberUuid = UuidTypeBuilder<"member">;
export type CaregiverSubscribedTrainingUuid = UuidTypeBuilder<"caregiver_subscribed_training">;
export type EventUuid = UuidTypeBuilder<"event">;
export type PrescriberProclientUuid = UuidTypeBuilder<"prescriber_proclient">;
export type PrescriberUuid = UuidTypeBuilder<"prescriber">;
export type StructureUuid = UuidTypeBuilder<"structure">;
export type ReplacementProposalFeedbackUuid = UuidTypeBuilder<"replacement_proposal_feedback">;

export type ServiceUuid = UuidTypeBuilder<"service">;
export type MonetPaymentUuid = UuidTypeBuilder<"monet_payments">;
export type GoCardLessID = UuidTypeBuilder<"gocardless">;
export type MonetPaymentIntentUuid = UuidTypeBuilder<"monet_payments_intents">;
export type MonetPaymentIntentEventUuid = UuidTypeBuilder<"monet_payment_intent_event">;
export type MonetBalanceUuid = UuidTypeBuilder<"monet_balances">;
export type MonetBankAccountUuid = UuidTypeBuilder<"monet_bank_account">;
export type UrssafUuid = UuidTypeBuilder<"urssaf">;
export type MonetPaymentBreakdownUuid = UuidTypeBuilder<"monet_payment_breakdown">;
export type MonetTaxCreditRequestUuid = UuidTypeBuilder<"monet_tax_credit_payments_requests">;
export type PackageUuid = UuidTypeBuilder<"package">;
export type PrescriberServiceUuid = UuidTypeBuilder<"prescriber_service">;
export type DeprecatedSubscribedServiceUuid = UuidTypeBuilder<"subscribed_service">;
export type SubscribedPackageUuid = UuidTypeBuilder<"subscribed_package_service">;
export type ThirdPartyPayerUuid = UuidTypeBuilder<"monet_third_party_payers">;
export type SharedHouseUuid = UuidTypeBuilder<"shared_house">;
export type SplittingRunUuid = UuidTypeBuilder<"shared_house_splitting_run">;
export type SharedHouseProclientMemberStayUuid =
  UuidTypeBuilder<"shared_house_proclient_member_stay">;

export type SurveyQuestionUuid = UuidTypeBuilder<"survey_question">;
export type SurveyUuid = UuidTypeBuilder<"survey">;
export type ContactUuid = UuidTypeBuilder<"contact">;
export type SignableUuid = UuidTypeBuilder<"signable">;
export type IssueUuid = UuidTypeBuilder<"issue">;
type ClosedByEventUuid = UuidTypeBuilder<"closed_issue">;
export type EnvelopeId = UuidTypeBuilder<"envelope">;
export type ReviewUuid = UuidTypeBuilder<"review">;
export type AssertionType = UuidTypeBuilder<"assertion">;
export type StaffingUuid = UuidTypeBuilder<"staffing">;
export type DeviceId = UuidTypeBuilder<"device_id">;
export type Zipcode = UuidTypeBuilder<"zipcode">;
export type CollaboratorGoogleID = UuidTypeBuilder<"google_uid">;
type CollaboratorMicrosoftID = UuidTypeBuilder<"microsoft_uid">;
export type CollaboratorDsitUuid = UuidTypeBuilder<"dsit_uuid">;
export type QaBatchUuid = UuidTypeBuilder<"qa_batch">;
export type RegularizationUuid = UuidTypeBuilder<"regularization">;
export type QaUuid = UuidTypeBuilder<"question">;
export type NotificationUuid = UuidTypeBuilder<"notification">;
export type CollaboratorTaskUuid = UuidTypeBuilder<"collaborator_task">;
export type EntityUuid = ProclientUuid | SharedHouseUuid;

export type FinancialHelpUuid = UuidTypeBuilder<"financial_help">;

// TODO: all those XimiId types seem to be wrong : the API always (or almost always)
// returns int for ximi_ids, not strings
export type ClientXimiId = TypeBuilder<string, "client_ximi_id">; // for Proclient
export type AgentXimiId = TypeBuilder<string, "agent_ximi_id">; // for Caregiver
export type PayslipXimiId = TypeBuilder<string, "payslip_ximi_id">; // for Caregiver
export type SharedHouseXimiId = TypeBuilder<string, "shared_house_ximi_id">;
export type InterventionXimiId = TypeBuilder<string, "intervention_ximi_id">;
export type ContractXimiId = TypeBuilder<string, "contract_ximi_id">;
export type AmendmentXimiId = TypeBuilder<string, "amendment_ximi_id">;
export type TypeformLandingId = TypeBuilder<string, "typeform_landing_id">;
export type MissionXimiId = TypeBuilder<string, "mission_ximi_id">;
export type InterventionToStaffId = TypeBuilder<string, "intervention_to_staff_id">;

export type SignedPlaceJson = TypeBuilder<string, "signed_place_json">;
export type AgencyUuid = TypeBuilder<string, "agency">;
export type FeatureFlag = TypeBuilder<string, "feature_flag">;
export type LegalEntityUuid = TypeBuilder<string, "legal_entity">;
export type MutualizedAreaUuid = TypeBuilder<string, "mutualized_area">;

//
// LOGS
//

export enum LogEntityType {
  caregiver = "caregiver",
  proclient = "proclient",
  prescriber = "prescriber",
}
type EntityLogsIdentifier<T extends {}, U extends string> = [T, U];
type CaregiverLogsIdentifier = EntityLogsIdentifier<Caregiver, LogEntityType.caregiver>;
type ProclientLogsIdentifier = EntityLogsIdentifier<Proclient, LogEntityType.proclient>;
type PrescriberLogsIdentifier = EntityLogsIdentifier<Prescriber, LogEntityType.prescriber>;

type AnyEntity = CaregiverLogsIdentifier | ProclientLogsIdentifier | PrescriberLogsIdentifier;

type AuditLog<E extends AnyEntity> = {
  uuid: AuditLogUuid;
  targetUuid: E[0]["uuid"];
  targetType: E[1];

  logType: "AUDIT_LOG";
  createdAt: DateTime;
  user: string;

  description: JsonValue;

  eventType: string;
};

export type AnyAuditIssueLog = AuditIssueLog | AuditClosedIssueLog;
export interface AuditIssueLog extends ProclientAuditLog {
  description: {
    happenedOn: DateTime | null;
    issueUuid: IssueUuid;
    title: string;
    type: ProclientIssueType;
  };
}
export interface AuditClosedIssueLog extends ProclientAuditLog {
  description: {
    closedAt: DateTime;
    closedByEventUuid: ClosedByEventUuid;
    issueUuid: IssueUuid;
    title: string;
  };
}

export type DebriefedEventLog = {
  uuid: DebriefedEventLogUuid;
  targetType: LogEntityType;
  targetEntities: EventTargetEntity[];

  logType: "DEBRIEFED_EVENT";
  createdAt: DateTime;
  debriefedAt: DateTime;
  plannedAt: DateTime;
  debriefedByDsitUuid: CollaboratorDsitUuid;
  user: Mail;

  subject: string;
  description: JsonValue | string;
  detailedReport: DetailedReport | null;

  contacts: BaseDebriefedEventContacts[];
  direction: EventDirection | null;
  eventType: EventTypes;
  eventTypeExtended: CallTypeExtended | VisitTypeExtended | null;
  eventResult: string | null;
  issuesUuids: IssueUuid[];
};

export type ProclientEventTargetEntity = {
  entityUuid: ProclientUuid;
  type: EventEntityType.proclient;
  rootBinding: boolean;
};
export type CaregiverEventTargetEntity = {
  entityUuid: CaregiverUuid;
  type: EventEntityType.caregiver;
  rootBinding: boolean;
};
export type PrescriberEventTargetEntity = {
  entityUuid: PrescriberUuid;
  type: EventEntityType.prescriber;
  rootBinding: boolean;
};
// Union by log type
export type AnyAuditLog = CaregiverAuditLog | ProclientAuditLog | PrescriberAuditLog;
// Union by entity
export type CaregiverLog = DebriefedEventLog | CaregiverAuditLog;
export type ProclientLog = DebriefedEventLog | ProclientAuditLog;
export type PrescriberLog = DebriefedEventLog | PrescriberAuditLog;

export type AnyLog = DebriefedEventLog | AnyAuditLog;

export const isDebriefedEventLog = (x: AnyLog): x is DebriefedEventLog =>
  x.logType === "DEBRIEFED_EVENT";

export const isAuditLog = (x: AnyLog): x is AnyAuditLog => x.logType === "AUDIT_LOG";

export const isCaregiverLog = (x: AnyLog): x is CaregiverLog =>
  x.targetType === LogEntityType.caregiver;

export const isPrescriberLog = (x: AnyLog): x is PrescriberLog =>
  x.targetType === LogEntityType.prescriber;

export const isProclientLog = (x: AnyLog): x is ProclientLog =>
  x.targetType === LogEntityType.proclient;

export type CaregiverAuditLog = AuditLog<CaregiverLogsIdentifier>;
export type ProclientAuditLog = AuditLog<ProclientLogsIdentifier>;
type PrescriberAuditLog = AuditLog<PrescriberLogsIdentifier>;
//
// End of logs
//

//
// EVENTS
//
export type EventTargetEntity =
  | ProclientEventTargetEntity
  | CaregiverEventTargetEntity
  | PrescriberEventTargetEntity;

export enum EventEntityType {
  caregiver = "caregiver",
  proclient = "proclient",
  prescriber = "prescriber",
}

//
// End of events
//

export enum DSITJob {
  rh_avs = "rh_avs",
  coordo = "coordo",
  rs = "rs",
  ra = "ra",
  rz = "rz",
  network_director = "network_director",
  lead_r_and_d_engineer = "lead_r_and_d_engineer",
  r_and_d_engineer = "r_and_d_engineer",
  ceu = "ceu",
  admin = "admin",
  rh_interne = "rh_interne",
  inside_sales = "inside_sales",
  central_business_developer_manager = "central_business_developer_manager",
  business_developer = "business_developer",
  marketing = "marketing",
  tech = "tech",
  produit = "produit",
  inside_sales_manager = "inside_sales_manager",
  em = "em",
  adr = "adr",
  dr = "dr",
  cfo = "cfo",
  cmo = "cmo",
  cpo = "cpo",
  cos = "cos",
  drh = "drh",
  quality_manager = "quality_manager",
  head_of_sales = "head_of_sales",
  head_of_partnership = "head_of_partnership",
  head_of_care = "head_of_care",
  deputy_coo = "deputy_coo",
  founder = "founder",
  lead_data_analyst = "lead_data_analyst",
  data_analyst = "data_analyst",
  knowledge_manager = "knowledge_manager",
  lnd_manager = "lnd_manager",
  sales_partnership = "sales_partnership",
  inside_sales_specialist = "inside_sales_specialist",
  sales_manager = "sales_manager",
}

export const DSITJobLabel: Record<DSITJob, string> = {
  [DSITJob.rh_avs]: "RH AVS",
  [DSITJob.coordo]: "Coordo",
  [DSITJob.rs]: "RS",
  [DSITJob.ra]: "RA",
  [DSITJob.rz]: "RZ",
  [DSITJob.network_director]: "Directrice du Réseau",
  [DSITJob.ceu]: "CEU",
  [DSITJob.admin]: "Pôle Admin",
  [DSITJob.rh_interne]: "RH",
  [DSITJob.inside_sales]: "Inside Sales",
  [DSITJob.central_business_developer_manager]: "Central Business Developer Manager",
  [DSITJob.business_developer]: "Business Developer",
  [DSITJob.marketing]: "Marketing",
  [DSITJob.tech]: "Tech",
  [DSITJob.produit]: "Produit",
  [DSITJob.inside_sales_manager]: "Inside Sales Manager",
  [DSITJob.em]: "Engineering Manager",
  [DSITJob.adr]: "Directeur Régional Adjoint",
  [DSITJob.dr]: "Directeur Régional",
  [DSITJob.cfo]: "Chief Financial Officer",
  [DSITJob.cmo]: "Chief Marketing Officer",
  [DSITJob.cpo]: "CPO",
  [DSITJob.cos]: "Chief of Staff",
  [DSITJob.drh]: "Directrice des Ressources Humaines",
  [DSITJob.quality_manager]: "Responsable de la Qualité",
  [DSITJob.head_of_sales]: "Directeur Commercial",
  [DSITJob.head_of_partnership]: "Directeur des partenariats",
  [DSITJob.sales_partnership]: "Sales Partenariat",
  [DSITJob.head_of_care]: "Directrice de l'Autonomie",
  [DSITJob.deputy_coo]: "Deputy COO",
  [DSITJob.founder]: "Fondateur",
  [DSITJob.lead_data_analyst]: "Lead Data Analyst",
  [DSITJob.data_analyst]: "Data Analyst",
  [DSITJob.knowledge_manager]: "Knowledge Manager",
  [DSITJob.lnd_manager]: "Learning & Development Manager",
  [DSITJob.r_and_d_engineer]: "R&D Ingénieur",
  [DSITJob.lead_r_and_d_engineer]: "Lead R&D Ingénieur",
  [DSITJob.inside_sales_specialist]: "Inside Sales Specialist",
  [DSITJob.sales_manager]: "Sales Manager",
};

export interface CollaboratorPhone {
  type: "home" | "work" | "mobile";
  value: string;
}

export interface Collaborator {
  agencyUuids: AgencyUuid[];
  birthday: DateTime | null;
  firstDay: DateTime | null;
  firstName: string;
  lastName: string;
  mail: Mail;
  dsitJob: DSITJob | null;
  deleted: boolean;
  phones: CollaboratorPhone[] | null;
  pictureUrl: string | null;
  place: string;
  roles: Array<string>;
  googleId: string;
  microsoftId: CollaboratorMicrosoftID;
  dsitUuid: CollaboratorDsitUuid;
  featureFlags: FeatureFlag[];
}

export type Department = {
  code: string;
  pretty: string;
  pretty_prefixed: string;
};

export type PlaceJson = {
  city: string;
  citycode: string;
  country: string;
  latitude: number;
  longitude: number;
  route: string;
  zipcode: string;
};

export type Place = PlaceJson & {
  fullAddress: string;
  signedPlaceJson: SignedPlaceJson;
  url: string;
  zipcity: string;
};

export type Zone = {
  departments: Department[];
  slug: string;
  pretty: string;
  leaderGender: string;
  leaderLastName: string;
  leaderName: string;
  prettyPrefixed: string;
  welcomeKitReference: string;
  phone: string;
  urssafRegionalCode: string;
  featureFlags: FeatureFlag[] | null;
};

export type FormErrors = OhApi.FieldErrors;

export type Agency = {
  uuid: AgencyUuid;
  zoneSlug: string;
  name: string;
  featureFlags: FeatureFlag[] | null;
  ximiSectorId: string | null;
  ghsJobId: string;
  googleAgencyId: string | null;
  mutualizedAreaUuid: MutualizedAreaUuid | null;
  placeJson: PlaceJson;
  signedPlaceJson: Place | null;
};

export type MutualizedArea = {
  uuid: MutualizedAreaUuid;
  name: string;
  aircallPhoneNumber: string;
  customerServiceId: number | null;
  agencyUuids: AgencyUuid[];
};

export enum LegalMode {
  CONTRACTOR = "contractor",
  MANDATARY = "mandatary",
}

export const LEGAL_MODE_LABEL: Record<LegalMode, string> = {
  [LegalMode.MANDATARY]: "Mandataire",
  [LegalMode.CONTRACTOR]: "Prestataire",
};

export type LegalEntity = {
  uuid: LegalEntityUuid;
  name: string;
  mode: LegalMode;
};

export enum MonitoringScoreLevel {
  POSITIVE = 0,
  WARNING = 1,
  NEGATIVE = 2,
}

type Permer = {
  requirementsDescription: { [permission: string]: string };
  securityPolicy: { [role: string]: string[] };
};

export const STATIC_DATA_MASTER_KEY = "staticData" as const;

export const staticDataKeys = {
  caregiverDiplomas: "caregiverDiplomas",
  caregiverTrainingDroppedReasons: "caregiverTrainingDroppedReasons",
  prescriberJobs: "prescriberJobs",
  allPackages: "allPackages",
  caregiverLanguages: "caregiverLanguages",
  caregiverSkills: "caregiverSkills",
  jobsSpecialities: "jobsSpecialities",
  structureTypes: "structureTypes",
  featureFlags: "featureFlags",
  permer: "permer",
} as const;

export type StaticData = {
  [staticDataKeys.caregiverDiplomas]: CaregiverDiplomaMap;
  [staticDataKeys.caregiverTrainingDroppedReasons]: CaregiverTrainingDroppedReasonsMap;
  [staticDataKeys.prescriberJobs]: PrescriberJobs;
  [staticDataKeys.allPackages]: PackageFromIDB[];
  [staticDataKeys.caregiverLanguages]: CaregiverLanguageMap;
  [staticDataKeys.caregiverSkills]: CaregiverSkillMap;
  [staticDataKeys.jobsSpecialities]: JobsSpecialities;
  [staticDataKeys.structureTypes]: StructureTypeMap;
  [staticDataKeys.featureFlags]: FeatureFlag[];
  [staticDataKeys.permer]: Permer;
};

export const cachedDataKey = {
  collaborators: "collaborators",
  zones: "zones",
  agencies: "agencies",
  mutualizedAreas: "mutualizedAreas",
  assertions: "assertions",
  generableDocuments: "generableDocuments",
  myAgencies: "myAgencies",
  activeServices: "activeServices",
  allServices: "allServices",
  allPackages: "allPackages",
  pricingStrategiesTemplates: "pricingStrategiesTemplates",
  financialHelps: "financialHelps",
} as const;

export type CachedData = {
  [cachedDataKey.collaborators]: Collaborator[];
  [cachedDataKey.zones]: Zone[];
  [cachedDataKey.agencies]: Agency[];
  [cachedDataKey.mutualizedAreas]: MutualizedArea[];
  [cachedDataKey.assertions]: Assertion[];
  [cachedDataKey.generableDocuments]: GenerableDocument[];
  [cachedDataKey.myAgencies]: Agency[];
  [cachedDataKey.activeServices]: ServiceFromIDB[];
  [cachedDataKey.allServices]: ServiceFromIDB[];
  [cachedDataKey.allPackages]: PackageFromIDB[];
  [cachedDataKey.pricingStrategiesTemplates]: MonetPricingStrategyTemplate[];
  [cachedDataKey.financialHelps]: FinancialHelp[];
};

export enum AnimatorRole {
  BUSINESS_DEVELOPER = "business_developer",
  OPS = "ops",
  NOBODY = "nobody",
}
