import { OhApi } from "siayuda";

import { CaregiverUuid, ProclientUuid } from "siayuda/oh-api";
import { MainCacheKeys } from "hassibot/services_v2/static";
import {
  LegalEntity,
  LegalMode,
  PrescriberUuid,
  StructureUuid,
} from "hassibot/services_v2/common/types";
import {
  ALL_ENTITY_STORES_NAMES,
  CacheEvictionStrategy,
  SimpleObjectStore,
} from "./simple-object-store";
import { ALL_LEGAL_MODES, cacheConfiguration } from "./config";

type StoreNameType = ALL_ENTITY_STORES_NAMES;
type StoreKeyType = CaregiverUuid | StructureUuid | ProclientUuid | PrescriberUuid | MainCacheKeys;
type StoreValueType = unknown;
type StoresType = Record<StoreNameType, SimpleObjectStore<StoreKeyType, StoreValueType>>;
type LegalEntityDatabase = {
  mode: LegalMode;
  stores: StoresType;
};

const isStoreNameValidForDatabase = (
  database: LegalEntityDatabase,
  storeName: string
): storeName is keyof typeof database.stores => {
  return Object.keys(database.stores).includes(storeName);
};

class IndexedDBCache {
  legalEntityDatabase: LegalEntityDatabase | null;

  constructor() {
    this.legalEntityDatabase = null;
  }

  initLegalEntityDatabase(legalEntity: LegalEntity): void {
    const stores = {} as StoresType;

    const filteredEntityStoresNames: ALL_ENTITY_STORES_NAMES[] = Object.values(
      ALL_ENTITY_STORES_NAMES
    ).filter(storeName =>
      [legalEntity.mode, ALL_LEGAL_MODES].includes(cacheConfiguration[storeName].mode)
    );

    filteredEntityStoresNames.forEach(storeName => {
      stores[storeName] = new SimpleObjectStore(
        storeName,
        legalEntity,
        filteredEntityStoresNames,
        cacheConfiguration[storeName].evictionStrategy
      );
    });

    this.legalEntityDatabase = {
      mode: legalEntity.mode,
      stores: stores,
    };
  }

  /**
   * Updates the value of the given key in the indexedDB store `storeName`
   */
  set<CustomKeyType extends StoreKeyType, CustomValueType extends StoreValueType>(
    storeName: StoreNameType,
    key: CustomKeyType,
    value: CustomValueType
  ): Promise<void> {
    if (!this.legalEntityDatabase) {
      throw new Error("Legal entity database not defined.");
    }
    if (!isStoreNameValidForDatabase(this.legalEntityDatabase, storeName)) {
      throw new Error("Unknown store name for current legal entity database (wrong mode).");
    }

    const store = this.legalEntityDatabase.stores[storeName];
    return store.set(key, value);
  }

  /**
   * Fetches the value of the given key in the indexedDB store `storeName`.
   * If none if found, use the callback to fetch the value and store it in the indexedDB store.
   */
  getOrCreate<CustomKeyType extends StoreKeyType, CustomValueType = StoreValueType>(
    storeName: StoreNameType,
    key: CustomKeyType,
    callback: () => Promise<OhApi.ApiResponse<CustomValueType>>,
    evictionStrategy?: CacheEvictionStrategy
  ): Promise<OhApi.ApiResponse<CustomValueType>> {
    if (!this.legalEntityDatabase) {
      throw new Error("Legal entity database not defined.");
    }
    if (!isStoreNameValidForDatabase(this.legalEntityDatabase, storeName)) {
      throw new Error("Unknown store name for current legal entity database (wrong mode).");
    }

    const store = this.legalEntityDatabase.stores[storeName] as SimpleObjectStore<
      StoreKeyType,
      CustomValueType
    >;

    return store.getOrCreate(key, callback, evictionStrategy);
  }

  /**
   * Delete the given key from the indexedDB store `storeName`.
   */
  delete(storeName: StoreNameType, key: StoreKeyType): Promise<void> {
    if (!this.legalEntityDatabase) {
      throw new Error("Legal entity database not defined.");
    }
    if (!isStoreNameValidForDatabase(this.legalEntityDatabase, storeName)) {
      throw new Error("Unknown store name for current legal entity database (wrong mode).");
    }

    const store = this.legalEntityDatabase.stores[storeName];
    return store.del(key);
  }
}

export const indexedDBCache = new IndexedDBCache();
