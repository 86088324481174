import { AgencyFilter } from "hassibot/component/filter/geography/types";
import { Agency } from "hassibot/services_v2/common/types";
import { SelectableAgency } from "hassibot/component/modals/agency-filter-modal/agency-filter-modal.types";
import { groupBy } from "lodash";

export const addSelectedStatusToAgencies = (
  selection: AgencyFilter[],
  agencies: Agency[]
): SelectableAgency[] =>
  agencies.map(agency => {
    const isIncludedAgency = selection.findIndex(a => a.value.uuid === agency.uuid) !== -1;

    return { ...agency, isSelected: isIncludedAgency };
  });

export const sortAndGroupAgenciesByZone = (
  agencies: SelectableAgency[]
): [string, SelectableAgency[]][] => {
  const groupedAgenciesByZone = groupBy(agencies || [], "zoneSlug");

  const sortedZones = Object.entries(groupedAgenciesByZone).sort(([zoneSlugA], [zoneSlugB]) =>
    zoneSlugA.localeCompare(zoneSlugB)
  );

  sortedZones.forEach(([_, zone]) =>
    zone.sort((agencyA, agencyB) => agencyA.name.localeCompare(agencyB.name))
  );

  return sortedZones;
};
