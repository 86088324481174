import { Spaces, patchRemResponsiveProps } from "emoreg/const";
import { PropsWithChildren, ReactNode } from "react";
import styled, { css } from "styled-components";
import { ResponsiveProps } from "styles/generic-styles-types";
import { responsiveHelpers } from "styles/utils";

export type Alignment = "center" | "left" | "right";
export type FlexAlignment = "center" | "flex-end" | "flex-start" | "revert" | "space-between";

export type GridProps = {
  gridTemplateColumns: string;
  columnGap?: Spaces;
  gridTemplateRows?: string;
  rowGap?: Spaces;
  alignItems?: FlexAlignment;
  justifyContent?: FlexAlignment;
  gridTemplateAreas?: string;
  height?: string;
};

const gridApi = (props: GridProps) => css`
  ${props.alignItems &&
  css`
    align-items: ${props.alignItems};
  `}
  ${props.gridTemplateAreas &&
  css`
    grid-template-areas: ${props.gridTemplateAreas};
  `}
  ${props.justifyContent &&
  css`
    justify-content: ${props.justifyContent};
  `}
${props.gridTemplateColumns &&
  css`
    grid-template-columns: ${props.gridTemplateColumns};
  `}
  ${props.gridTemplateRows &&
  css`
    grid-template-rows: ${props.gridTemplateRows};
  `}
  ${props.rowGap &&
  css`
    row-gap: ${patchRemResponsiveProps(props.rowGap)};
  `}
  ${props.columnGap &&
  css`
    column-gap: ${patchRemResponsiveProps(props.columnGap)};
  `}
  ${props.height &&
  css`
    height: ${props.height};
  `}
`;

const GridStyle: React.FC<PropsWithChildren<ResponsiveProps<GridProps>>> = styled.div`
  display: grid;
  ${(props: ResponsiveProps<GridProps>) => gridApi(props.mobile)}
  ${responsiveHelpers.forPortraitTabletUp`
  ${(props: ResponsiveProps<GridProps>) => props.tablet && gridApi(props.tablet)}
  `}
  ${responsiveHelpers.forLandscapeTabletUp`
  ${(props: ResponsiveProps<GridProps>) => props.desktop && gridApi(props.desktop)}
  `}
`;
/**
 * @deprecated Use new-layouts/Grid instead
 */
export const Grid = ({
  children,
  ...restProps
}: ResponsiveProps<GridProps> & { children: ReactNode[] | ReactNode }) => {
  return <GridStyle {...restProps}>{children}</GridStyle>;
};
