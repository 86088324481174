/* eslint-disable no-restricted-imports */
// This is the ONLY place where we import Route directly
import { ReactElement } from "react";
import { Route as ReactRouterRoute, RouteComponentProps } from "react-router-dom";
import { Error404 } from "./component/error-404";
import { LegalMode, LEGAL_MODE_LABEL } from "./services_v2/common/types";
import styled from "styled-components";
import { useLegalMode } from "./current-legal-entity-provider";

const Error404Container = styled.div`
  text-align: -webkit-center;
`;

/**
 * an overload of Route
 * Will check that the current legal entity mode allow to access this Route
 */
export const Route = ({
  path,
  legalModes,
  exact,
  render,
  component,
}: {
  path: string;
  legalModes: LegalMode[];
  exact?: boolean;
  render?: (any) => ReactElement;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}): ReactElement | null => {
  const { mode } = useLegalMode();

  if (legalModes.includes(mode)) {
    return <ReactRouterRoute exact={exact} path={path} render={render} component={component} />;
  }

  return (
    <Error404Container>
      <Error404 mainText={`Cette page n'est pas accessible en mode ${LEGAL_MODE_LABEL[mode]}.`} />
    </Error404Container>
  );
};
