import { chunk } from "lodash";

export const head = <A>(arr: A[]): A | undefined => {
  const [first] = arr;
  return first;
};

export const splitArrayInChunks = <T>(arr: T[], numberOfChunks: number): T[][] => {
  const itemsPerPart = Math.ceil(arr.length / numberOfChunks);
  return chunk(arr, itemsPerPart);
};
