import React from "react";
import { rem } from "polished";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { ifProp, switchProp } from "styled-tools";

import { lhCrop } from "emoreg/utils/lh-crop";
import { color, radius, spaces } from "emoreg/const";
import { transition } from "styles/const";

import { Icon } from "emoreg/atoms/Icon";

const InputTextWithIcon = styled.div`
  display: block;
  position: relative;
  svg {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 16px;
    margin: auto;
    top: 0;
    bottom: 0;
  }
`;

const Input = styled.input`
  ${lhCrop(1)};
  border: 0.5px solid ${color.grey.base};
  padding: ${spaces[4]} ${spaces[12]};
  height: ${rem(32)};
  border-radius: ${radius.base};
  background-color: #fff;
  color: ${color.grey.darker};
  width: 100%;
  transition: 0.5s ${transition.cubicEaseInOut};

  ${switchProp("size", {
    sm: `padding: ${spaces[8]} ${spaces[12]};`,
    md: `padding: ${spaces[12]} ${spaces[16]};`,
    lg: `padding: ${spaces[12]} ${spaces[16]};`,
  })};

  ${ifProp("icon", `padding-left: ${spaces[48]};`)};

  ${ifProp(
    { disabled: true },
    `
    cursor: not-allowed;
    color: ${color.grey.dark};
    background-color: ${color.grey.light};
    border: 1px solid ${color.grey.base};
    `
  )};

  ${ifProp("error", `border-color: ${color.red.base};`)}

  ${switchProp("size", {
    md: `font-size: .875rem`,
    lg: `font-size: 1rem`,
  })};

  &::placeholder {
    color: ${color.grey.dark};
  }

  &:focus {
    border-color: ${color.blue.base};
    outline: none;
    transition: 0.3s ${transition.cubicEaseInOut};
  }
  ${ifProp(
    "readOnly",
    css`
      background-color: #e9ecef;
      opacity: 1;
      &:focus {
        border-color: ${color.grey.base};
        outline: none;
        transition: unset;
      }
    `
  )}
`;

/**
 * @deprecated
 */
class InputText extends React.Component {
  render() {
    const {
      type,
      placeholder,
      error,
      icon,
      size,
      onChange,
      disabled,
      value,
      autoFocus,
      readOnly,
      id,
      inputRef,
      inputMode,
      pattern,
      onBlur,
      ...rest
    } = this.props;
    return icon ? (
      <InputTextWithIcon>
        <Icon size={16} name={icon} color={color.grey.base} />
        <Input
          placeholder={placeholder}
          icon={icon}
          id={id}
          error={error}
          size={size}
          autoFocus={autoFocus}
          value={value}
          onChange={e => onChange(e.target.value)}
          type={type ? type : "text"}
          disabled={disabled}
          onBlur={onBlur ? e => onBlur(e.target.value) : null}
          readOnly={readOnly}
          {...rest}
        />
      </InputTextWithIcon>
    ) : (
      <Input
        onBlur={onBlur ? e => onBlur(e.target.value) : null}
        ref={inputRef}
        disabled={disabled}
        value={value}
        size={size}
        error={error}
        id={id}
        autoFocus={autoFocus}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        type={type ? type : "text"}
        readOnly={readOnly}
        inputMode={inputMode}
        pattern={pattern}
        {...rest}
      />
    );
  }
}

InputText.defaultProps = {
  size: "sm",
};

InputText.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

export default InputText;
