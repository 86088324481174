import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ifProp } from "styled-tools";

import { spaces } from "emoreg/const";
import { Title } from "emoreg/atoms/Title";

const SidebarSectionInner = styled.div`
  ${ifProp(
    { align: "center" },
    `display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;`
  )};

  &:not(:last-child) {
    margin-bottom: ${spaces[24]};
  }
`;

const SidebarSection = ({ title, children, lightBackground, align }) => (
  <SidebarSectionInner align={align} lightBackground={lightBackground}>
    <Title type="h4">{title}</Title>
    {children}
  </SidebarSectionInner>
);

SidebarSection.defaultProps = {
  align: "left",
};

SidebarSection.propTypes = {
  title: PropTypes.string,
  lightBackground: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
};

export default SidebarSection;
