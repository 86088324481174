import { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { rem } from "polished";

import { color, radius, spaces } from "emoreg/const";
import { lhCrop } from "emoreg/utils/lh-crop";
import { InputProps, StaticInputProps } from "emoreg/atoms/Inputs/types";
import { CommonInputishPossiblyWithIcon } from "./common";
import { focusableInputStyle } from "emoreg/atoms/Inputs/common.styles";

const InputSelectStyle: React.FC<
  ComponentProps<"select"> & {
    hasError?: boolean;
    hasIcon: boolean;
  }
> = styled.select`
  ${lhCrop(1)};
  ${focusableInputStyle};
  border: 0.5px solid ${color.grey.base};
  width: 100%;
  padding: ${spaces[4]} 28px ${spaces[4]} ${spaces[12]};
  border-radius: ${radius.base};
  height: ${rem(32)};
  font-size: ${rem(14)};
  color: ${props => {
    if (props.value === "__DEFAULT__") {
      return color.grey.dark;
    } else {
      return props.disabled ? color.grey.base : color.grey.darker;
    }
  }};

  // ensure (especially for windows) that option has black color
  & > options {
    color: ${color.grey.darker};
  }

  background: transparent;
  background-color: ${props => (props.disabled ? "#F2F2F2" : "#fff")};
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.58579 6.25C5.47669 6.25 5.92286 7.32714 5.29289 7.95711L3.95711 9.29289C3.56658 9.68342 2.93342 9.68342 2.54289 9.29289L1.20711 7.95711C0.577143 7.32714 1.02331 6.25 1.91421 6.25H4.58579Z' fill='%23707579'/%3E%3Cpath d='M1.91421 3.75C1.02331 3.75 0.577142 2.67286 1.20711 2.04289L2.54289 0.707107C2.93342 0.316583 3.56658 0.316583 3.95711 0.707107L5.29289 2.04289C5.92286 2.67286 5.47669 3.75 4.58579 3.75L1.91421 3.75Z' fill='%23707579'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 12px);
  background-position-y: calc(50%);
  ${ifProp(
    "hasError",
    css`
      border: 0.5px solid ${color.red.base};
    `
  )}
  ${ifProp(
    "hasIcon",
    css`
      padding-left: ${rem(34)}; // this padding is out of sizes const
    `
  )}
`;

export type InputSelectProps<T extends string | undefined> = StaticInputProps &
  InputProps<T> & {
    iconComponent?: () => JSX.Element;
    options: SelectOpt[];
  };
type BaseSelectOpt = {
  label?: ReactNode;
  value: string;
  disabled?: boolean;
};
type GroupedSelectOpt = BaseSelectOpt & { group: string };
export type SelectOpt = BaseSelectOpt | GroupedSelectOpt;

const Option = (props: SelectOpt) => (
  <option value={props.value} disabled={props.disabled}>
    {props.label}{" "}
  </option>
);
const isGroup = (opt: SelectOpt): opt is GroupedSelectOpt => "group" in opt;
export function InputSelect<T extends string | undefined>(props: InputSelectProps<T>) {
  const groupedOptions: Record<string, SelectOpt | GroupedSelectOpt[]> = props.options.reduce(
    (acc, opt, index) => {
      if (isGroup(opt)) {
        return {
          ...acc,
          [opt.group]: [...(acc[opt.group] || []), opt],
        };
      } else {
        return {
          ...acc,
          [index]: opt,
        };
      }
    },
    {}
  );
  return (
    <CommonInputishPossiblyWithIcon {...props}>
      <InputSelectStyle
        required
        disabled={props.disabled}
        hasError={!!props.errorText}
        hasIcon={!!props.iconComponent}
        onChange={e => props.onChange(e.target.value as T)}
        value={props.value || "__DEFAULT__"}
      >
        {props.placeholder && (
          <option id="placeholder" disabled value={"__DEFAULT__"}>
            {props.placeholder}
          </option>
        )}
        {Object.values(groupedOptions).map((g, i) => {
          if (Array.isArray(g)) {
            return (
              <optgroup label={g[0].group} key={i}>
                {g.map((o, i) => (
                  <Option {...o} key={i} />
                ))}
              </optgroup>
            );
          } else {
            return <Option {...g} key={i} />;
          }
        })}
      </InputSelectStyle>
    </CommonInputishPossiblyWithIcon>
  );
}
