import {
  AnyAuditIssueLog,
  AnyLog,
  AuditClosedIssueLog,
  AuditIssueLog,
  DebriefedEventLog,
  isDebriefedEventLog,
} from "hassibot/services_v2/common/types";

export const isAnyAuditIssueLog = (l: AnyLog): l is AnyAuditIssueLog =>
  l.eventType?.includes("ISSUE");

export const isClosedAuditIssueLog = (l: AnyLog): l is AuditClosedIssueLog =>
  isAnyAuditIssueLog(l) && l.eventType === "CLOSE_ISSUE";

export const isAuditIssueLog = (l: AnyLog): l is AuditIssueLog => isAnyAuditIssueLog(l);

export const isDebriefedEventIssueLog = (l: AnyLog): l is DebriefedEventLog =>
  isDebriefedEventLog(l) && l.issuesUuids ? l.issuesUuids.length > 0 : false;

export const isIssueRelated = (l: AnyLog): l is AuditIssueLog | DebriefedEventLog =>
  isAnyAuditIssueLog(l) || isDebriefedEventIssueLog(l);

// Do not reproduce. Dirty Hack.
const logsWithoutActor = ["SELF_UPDATE"];
export const isLogWithActor = (log: AnyLog): boolean => {
  return !logsWithoutActor.includes(log.eventType);
};

export const isCoupleEntityCouple = (entity): boolean =>
  "members" in entity && entity.members && entity.members.length > 1;

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}
