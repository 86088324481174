import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { fr } from "date-fns/locale";

import { InputText } from "styles/atoms";

import { StyledDatePicker } from "./calendar";

const InputTextTimePicker = styled(InputText)`
  width: 100%;
`;

const CustomInput = ({ value, error, onChange, ...rest }) => {
  return (
    <div style={{ display: "flex" }}>
      <InputTextTimePicker
        size="sm"
        value={value}
        onChange={v => onChange({ target: { value: v } })}
        error={error}
        {...rest}
      />
    </div>
  );
};

type InputDatePickerProps = {
  onChange?: (v: DateTime | null) => void;
  value: DateTime | null;
  error?: string | string[] | JSX.Element;
  placeholder?: string;
  timeFormat?: string;
  dateFormat?: string;
  timeCaption?: string;
  showTimeSelect?: boolean;
  minDate?: DateTime;
  id?: string;
};

export const InputDatePicker = ({
  onChange,
  value,
  error,
  placeholder,
  timeFormat,
  dateFormat,
  timeCaption,
  showTimeSelect,
  minDate,
  id,
}: InputDatePickerProps) => {
  return (
    <StyledDatePicker>
      <DatePicker
        id={id}
        onSelect={
          onChange ? value => onChange(value ? DateTime.fromJSDate(value) : null) : undefined
        }
        onChange={
          // We don't allow the range mode which would result in
          // `value` being `[Date, Date]`, hence the type cast.
          onChange
            ? value => onChange(value ? DateTime.fromJSDate(value as Date) : null)
            : () => undefined
        }
        selected={value ? value.toJSDate() : null}
        placeholderText={placeholder}
        showTimeSelect={showTimeSelect}
        // @ts-ignore: Weird way to pass a component...
        customInput={<CustomInput error={error} />}
        timeFormat={timeFormat ? timeFormat : undefined}
        dateFormat={dateFormat ? dateFormat : undefined}
        timeCaption={timeCaption ? timeCaption : undefined}
        minDate={minDate ? minDate.toJSDate() : null}
        locale={fr}
      />
    </StyledDatePicker>
  );
};
