import { OhApi } from "siayuda";
import { formatPrerequisiteData } from "./missing-prerequisite-data";

export type ErrorExtra = string[] | { missingData: string[] } | undefined;

const isMissingDataError = (e: ErrorExtra): e is { missingData: string[] } =>
  !!e && "missingData" in e && Array.isArray(e.missingData);

export const signableErrors: Record<string, (extraError: ErrorExtra) => string[]> = {
  REQUIRED: _ => ["Champ requis"],
  INVALID: _ => ["Valeur incorrecte"],
  DOCUMENTS_DOES_NOT_MEET_SPECIFICATIONS_TO_BE_SIGNED: _ => [
    "Les documents selectionné ne remplissent pas les conditions nécessaire.",
  ],
  CANNOT_GENERATE_DOCUMENT_FOR_COUPLES: _ => ["Impossible de générer un document pour un couples"],
  SIGNATORY_NOT_FOUND: _ => ["Signataire inconnu"],
  UNKNOWN_OUIHELP_REPRESENTATIVE: () => [
    "Représentant de ouihelp inconnu. Vérifiez l'email associé.",
  ],
  DOCUMENTS_NOT_FOUND: () => [
    "Documents associés inconnu. Vérifier qu’ils sont bien générés sur Hassibot",
  ],
  REPRESENTATIVE_NOT_KNOWN_BY_DOCUSIGN: () => [
    "Aucun compte Docusign associé à ce représentant Ouihelp. Merci de créer le compte associé, si il vient d'être crée, il sera actif sur Hassibot d'ici 1h.",
  ],
  MONET_BANK_ACCOUNT_ALREADY_USED: () => [
    "Le compte bancaire actuel est déja associé à un mandat SEPA",
  ],
  MISSING_DATA_FOR_SIGNATORY: extraError => {
    if (!Array.isArray(extraError)) return [];
    else {
      const translateField = {
        mail: "Email",
      };
      return [
        `${extraError
          ?.map(k => translateField[k])
          .join(
            ", "
          )} du signataire sélectionné manquant. Veuillez renseigner le/les champ.s manquant.s`,
      ];
    }
  },
  COLLABORATOR_NOT_KNOWN_BY_DOCUSIGN: () => ["Vous n'avez pas de compte DocuSign"],
  MISSING_PREREQUISITE_DATA: extraError => {
    return isMissingDataError(extraError)
      ? formatPrerequisiteData(extraError.missingData)
      : ["Une information est manquante pour générer le document"];
  },
  GENERATE_DISABLED_FOR_COUPLE: () => [
    "La géneration de document n'est pas gérée pour les couples",
  ],
  BANK_ACCOUNT_ALREADY_BOUND_TO_DOCUMENT: () => [
    "La section \"Mandat SEPA\" contient déjà un document : s'il s'agit effectivement d'un mandat SEPA, inutile d'en générer un deuxième.\nS'il s'agit d'une erreur, veuillez déplacer le document existant dans la section \"Autres documents\", puis générer ou ajouter le mandat SEPA",
  ],
  DELEGATE_CANT_BE_REPRESENTATIVE: () => [
    "Le référent ne peut pas signer sa propre délégation de pouvoir (le bénéficiaire et le référent doivent être deux personnes distinctes)",
  ],
  CANNOT_SEND_TO_XIMI_FOR_COUPLES: _ => [
    "Impossible d'envoyer un document sur Ximi pour un couple",
  ],
};

export const handleDocumentGlobalError = (resp: OhApi.GlobalError): string[] => {
  const f = signableErrors[resp.error];
  return f?.(resp.extra as ErrorExtra) || ["Erreur inconnue, veuillez contacter la tech"];
};
