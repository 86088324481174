import styled from "styled-components";
import { radius } from "styles/const";
import { color } from "emoreg/const";
import { spaces } from "emoreg/const";

export const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    font-family: "Source Sans Pro";
    border-radius: ${radius.base};
    border: 1px solid ${color.grey.base};

    &__header {
      padding-top: 0;
      background: #fff;
      border-bottom: 0.5px solid transparent;
    }

    &__navigation {
      outline: none;
      margin: 0.4rem 0 0 0;
    }

    &__week {
      display: flex;
    }
  }

  .react-datepicker__day {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    margin: ${spaces[4]};
    border-radius: ${radius.base};
    line-height: initial;
    font-size: 14px;
    width: ${spaces[48]};
    height: ${spaces[32]};

    &--selected,
    &--selected.react-datepicker__day--today {
      background: ${color.blue.base};
      color: #fff;
    }

    &--outside-month {
      opacity: 0.6;
    }

    &--today {
      color: ${color.grey.darker};
    }

    &:hover {
      border-radius: ${radius.base};
    }

    &-name {
      margin: ${spaces[4]};
    }
  }

  .react-datepicker__current-month {
    margin-bottom: ${spaces[12]};
    padding: ${spaces[16]} 0;
    border-bottom: 0.5px solid ${color.grey.base};
  }

  .react-datepicker__day-name {
    width: ${spaces[48]};
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month {
    line-height: 1rem;
    font-weight: bolder;
    color: ${color.grey.dark};
  }
`;
