import { DateTime, Duration } from "luxon";
import moment from "moment";
import { handlePlural } from "./formatters";

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";
export const ALL_TIME = "ALL_TIME";

const PERIODS = [DAILY, WEEKLY, MONTHLY, ALL_TIME];

export const DISPLAY_DATE_FORMAT = "DD[/]MM[/]YYYY";
export const API_DATE_FORMAT = "YYYY-MM-DD";

/**
 * Return the sort integer between datishA and datishB.
 * @param {datishA} date - A date to compare
 * @param {datishB} date - A date to compare
 * @returns {integer} Integer sorting datishA and datishB
 */
export function dateSort(datishA, datishB) {
  datishA = datishA === null ? moment(new Date(0)) : datishA;
  datishB = datishB === null ? moment(new Date(0)) : datishB;
  if (datishA.isBefore(datishB)) {
    return -1;
  }
  if (datishA.isAfter(datishB)) {
    return 1;
  }
  return 0;
}

/**
 * Return the label corresponding to a period.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date (moment object) inside the wanted period
 * @returns {string} Label corresponding to the given period type and date
 */
export function periodLabel(periodType, date) {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }

  if (periodType === ALL_TIME) {
    return ALL_TIME;
  }

  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  if (periodType === DAILY) {
    return date.format("YYYY-MM-DD");
  }

  if (periodType === WEEKLY) {
    return date.format("GGGG[-W]WW");
  }

  if (periodType === MONTHLY) {
    return date.format("YYYY-MM");
  }
}

/**
 * Return the humanly readable string corresponding to the start and the end of a period.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date (moment object) inside the wanted period
 * @returns {string} Label corresponding to the given period type and date
 */
export function periodHumanDates(periodType, date) {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }

  if (periodType === ALL_TIME) {
    return "Depuis le tout début";
  }

  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  if (periodType === DAILY) {
    return date.format(DISPLAY_DATE_FORMAT);
  }

  const firstDay = firstDayOfPeriod(periodType, date),
    lastDay = lastDayOfPeriod(periodType, date);
  return `${firstDay?.format(DISPLAY_DATE_FORMAT)} - ${lastDay?.format(DISPLAY_DATE_FORMAT)}`;
}

/**
 * Return the date (moment object) of the first day of the given period.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date inside the wanted period
 * @returns {moment} Date (moment object) of the first day of the given period
 */
export function firstDayOfPeriod(periodType, date): any {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }
  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  if (periodType === DAILY) {
    // We clone it to avoid surprises... moment objects are mutable.
    return moment(date);
  }

  if (periodType === WEEKLY) {
    return moment(date.format("GGGGWW1"), "GGGGWWE");
  }

  if (periodType === MONTHLY) {
    return moment(date.format("YYYYMM01"), "YYYYMMDD");
  }
}

/**
 * Return the date (moment object) of the last day of the given period.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date inside the wanted period
 * @returns {moment} Date (moment object) of the last day of the given period
 */
export function lastDayOfPeriod(periodType, date) {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }
  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  if (periodType === DAILY) {
    // We clone it to avoid surprises... moment objects are mutable.
    return moment(date);
  }

  if (periodType === WEEKLY) {
    return moment(date.format("GGGGWW7"), "GGGGWWE");
  }

  if (periodType === MONTHLY) {
    let rv = firstDayOfPeriod(periodType, date);
    rv?.add(1, "months").subtract(1, "days");
    return rv;
  }
}

/**
 * Return the date (moment object) of the first day of the next period.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date inside the reference period
 * @returns {moment} Date (moment object) of the first day of the next period
 */
export function firstDayOfNextPeriod(periodType, date) {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }

  if (periodType === ALL_TIME) {
    return moment(new Date(2090, 5, 27));
  }

  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  if (periodType === DAILY) {
    return firstDayOfPeriod(periodType, date)?.add(1, "days");
  }

  if (periodType === WEEKLY) {
    return firstDayOfPeriod(periodType, date)?.add(7, "days");
  }

  if (periodType === MONTHLY) {
    return firstDayOfPeriod(periodType, date)?.add(1, "months");
  }
}

/**
 * Return the date (moment object) of the first day of the previous period if
 * not before the 01/06/2016, otherwise return null.
 * @param {string} periodType - A known period type (see `hassibot.util.date.PERIODS`)
 * @param {moment} date - A date inside the reference period
 * @returns {moment} Date (moment object) of the first day of the next period
 */
export function firstDayOfPreviousPeriod(periodType, date) {
  if (!PERIODS.includes(periodType)) {
    throw new Error("Unrecognized `periodType`");
  }

  if (periodType === ALL_TIME) {
    return null;
  }

  if (!moment.isMoment(date)) {
    throw new Error("Unrecognized `date`");
  }

  let rv;

  if (periodType === DAILY) {
    rv = firstDayOfPeriod(periodType, date)?.subtract(1, "days");
  }

  if (periodType === WEEKLY) {
    rv = firstDayOfPeriod(periodType, date)?.subtract(7, "days");
  }

  if (periodType === MONTHLY) {
    rv = firstDayOfPeriod(periodType, date)?.subtract(1, "months");
  }

  /*
   * Weird comparison to deal with moment objects (which are datetimes and
   * not dates)
   */
  if (rv.format("YYYYMMDD") >= "20160601") {
    return rv;
  }
  return null;
}

export const periodTypeToLabelMap = {
  WEEKLY: "Hebdomadaire",
  MONTHLY: "Mensuel",
  DAILY: "Quotidien",
  ALL_TIME: "Tout",
};

export const humanShortDate = (dt, format = "dd/MM") => {
  const datish = dt.startOf("day");
  const today = DateTime.local().startOf("day");
  const yesterday = today.minus({ days: 1 }).startOf("day");
  const tomorrow = today.plus({ days: 1 }).startOf("day");
  if (+datish === +yesterday) {
    return "hier";
  } else if (+datish === +today) {
    return "aujourd'hui";
  } else if (+datish === +tomorrow) {
    return "demain";
  }
  return dt.toFormat(format);
};

export const humanShortDatetime = (dt, date_format = "dd/MM", time_format = "hh:mm") => {
  const date = humanShortDate(dt, date_format);
  const time = dt.toFormat(time_format);
  return `${date}${time}`;
};

export const formatTotalExperience = (year: number): string => {
  const month = year * 12;
  return monthsToYear(month);
};

export const monthsToYear = (seniority: number): string => {
  const year = Math.trunc(seniority / 12);
  const month = seniority % 12;
  if (year > 0) {
    return year + ` an${year > 1 ? "s" : ""} ${month > 0 ? month + " mois" : ""}`;
  } else if (month > 0) {
    return month + " mois";
  } else {
    return "< 1 mois";
  }
};

export const formatInterventionDuration = (
  startAt?: DateTime | null,
  endAt?: DateTime | null
): string => {
  if (startAt && endAt) {
    const duration = endAt.diff(startAt, ["hours", "minutes"]);
    return duration.toFormat("h'h'mm");
  } else return "?";
};

export const isMidnightDatetime = (datetime: DateTime): boolean => {
  return datetime.get("hour") === 0 && datetime.get("minute") === 0 && datetime.get("second") === 0;
};

/**
 * @description This function is taking hour and minutes and format it with luxon tokens. We are preventing 00min and 00h10min
 */
export const hourMinutesToString = (hours?: number, minutes?: number) => {
  const templateStringHours = hours && hours > 0 ? "h'h'" : "";
  const templateStringMinutes = minutes && minutes > 0 ? "mm'min'" : "";
  const templateString = [templateStringHours, templateStringMinutes].join("");

  return Duration.fromObject({
    hours: hours,
    minutes: minutes,
  }).toFormat(templateString);
};

/**
 * @description Convert a date moment to iso datetime. Optionnally add days to initial date.
 */
const dateToIsoDatetime = (date: moment.Moment, daysNumberToAdd?: number): string => {
  var datetime = DateTime.fromISO(date.format(API_DATE_FORMAT));

  if (daysNumberToAdd) {
    datetime = datetime.plus({ days: daysNumberToAdd });
  }

  return datetime.startOf("day").toISO();
};

/**
 * @description Get filter datetime range from a reference date and a period's type ("DAILY" | "WEEKLY" | "MONTHLY")
 */
export const getFilterDatetimeRange = (
  periodType: string,
  referenceDate: string
): [string, string] => {
  const firstDay = firstDayOfPeriod(periodType, moment(referenceDate));
  const lastDay = lastDayOfPeriod(periodType, moment(referenceDate));

  return [dateToIsoDatetime(firstDay), dateToIsoDatetime(lastDay, 1)];
};

/**
 * @description Returns the number of days elapsed since the provided datetime
 */
export const getDaysSince = (datetime: DateTime): number =>
  DateTime.now().diff(datetime).as("days");

/**
 *
 * @param date
 * @returns date since in human readable format (e.g. 2 jours, 1 mois)
 */
export const getElapsedDurationLabel = (date: DateTime, elapsedPrefix = "Depuis") => {
  const numberOfDays = Math.floor(getDaysSince(date));
  const isToday = DateTime.now().hasSame(date, "day");

  if (isToday) {
    return "Aujourd'hui";
  }

  if (numberOfDays <= 30) {
    return `${elapsedPrefix} ${numberOfDays} ${handlePlural(numberOfDays, {
      plural: "jours",
      singular: "jour",
    })}`;
  }

  if (numberOfDays <= 365) {
    const numberOfMonths = Math.floor(numberOfDays / 30);
    return `${elapsedPrefix} ${numberOfMonths} ${handlePlural(numberOfMonths, {
      plural: "mois",
      singular: "mois",
    })}`;
  }

  const numberOfYears = Math.floor(numberOfDays / 365);
  return `${elapsedPrefix} ${numberOfYears} ${handlePlural(numberOfYears, {
    plural: "ans",
    singular: "an",
  })}`;
};

export const addWorkingDays = (date: DateTime, days: number) => {
  let remainingDays = days;
  while (remainingDays > 0) {
    date = date.plus({ days: 1 });
    if (![6, 7].includes(date.weekday)) {
      remainingDays -= 1;
    }
  }
  return date;
};

export type Weekdays =
  | "lundi"
  | "mardi"
  | "mercredi"
  | "jeudi"
  | "vendredi"
  | "samedi"
  | "dimanche";
