import {
  CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY as ENTITY_UUID_STORAGE_KEY,
  CURRENT_LEGAL_MODE_LOCAL_STORAGE_KEY as ENTITY_MODE_STORAGE_KEY,
} from "hassibot/current-legal-entity-provider";
import { get } from "hassibot/local-storage";
import { OhApi } from "siayuda";

import { deleteUserCredentials, userCredentialsSubject } from "token-manager";
import { LegalEntityUuid, LegalMode } from "./common/types";

// siayuda does not allow us to have a "logged out" client, we hack it with
// this.
const FAKE_TOKEN = "FAKE_INVALID_TOKEN";

const currentLegalEntityUuid = get(ENTITY_UUID_STORAGE_KEY) as null | LegalEntityUuid;
export const currentLegalMode = get(ENTITY_MODE_STORAGE_KEY) as null | LegalMode;
const auth = new OhApi.AuthConfig("HB", FAKE_TOKEN);

class LegalEntityRestrictedApiClient extends OhApi.ApiV2Client {
  public buildHeaders = (additionalHeaders?: Record<string, string>): Headers =>
    new Headers({
      ...(this.config.auth && this.config.auth.makeAuthHeader()),
      ...(currentLegalEntityUuid && { "X-LEGAL-ENTITY-UUID": currentLegalEntityUuid }),
      ...additionalHeaders,
    });
}

export const client = new LegalEntityRestrictedApiClient({
  auth,
  preProcessingResponseHooks: [
    async (resp): Promise<void> => {
      if (resp.status === 401) {
        await deleteUserCredentials();
      }
    },
  ],
});

userCredentialsSubject.subscribe(creds => {
  client.updateToken(creds ? creds.token : FAKE_TOKEN);
});
