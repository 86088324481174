import { PropsWithChildren } from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import { omit } from "lodash";

import { Label, TextArea, InputText, InputSubtext, InputSelect } from "styles/atoms";
import { color } from "emoreg/const";
import { InputDatePicker } from "./input-datepicker";

const RequiredText = styled.span`
  color: ${color.red.base};
`;

type IternalFormFieldProps = {
  id?: string;
  label?: string | JSX.Element;
  required?: boolean;
  error?: string[] | string | JSX.Element | null;
  helper?: string | JSX.Element;
};

export const FormField = ({
  id,
  label,
  required,
  children,
  error,
  helper,
}: PropsWithChildren<IternalFormFieldProps>) => (
  <>
    {label ? (
      <Label htmlFor={id} style={{ marginBottom: 0 }}>
        {label} {required ? <RequiredText>*</RequiredText> : null}
      </Label>
    ) : required ? (
      <RequiredText>*</RequiredText>
    ) : null}
    {children}
    {error ? <FieldErrors error={error} /> : null}
    {helper ? <InputSubtext type="helper">{helper}</InputSubtext> : null}
  </>
);

export const FieldErrors = ({ error }: { error: string[] | string | JSX.Element }) => {
  const errs = Array.isArray(error) ? error : [error];
  return errs.length > 0 ? (
    <InputSubtext type="error">
      {errs.length > 1 ? (
        <ul>
          {errs.map((e, key) => (
            <li key={key}>{e}</li>
          ))}
        </ul>
      ) : (
        errs[0]
      )}
    </InputSubtext>
  ) : null;
};

type FormFieldProps = Omit<IternalFormFieldProps, "children"> & {
  onChange?: (v: any) => void;
  disabled?: boolean;
  value: any;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  inputMode?: string;
  pattern?: string;
};

type TextFieldSpecific = {
  type?: "text" | "number" | "regex" | "color" | "email" | "password" | "tel" | "time" | "url";
  size?: Size;
  onBlur?: (value: string) => void;
};

type TextFieldProps = FormFieldProps & TextFieldSpecific;

export const TextField = ({
  id,
  label,
  required,
  error,
  helper,
  disabled,
  value,
  onChange,
  type,
  autoComplete,
  inputMode,
  pattern,
  ...rest
}: TextFieldProps) => {
  const newRest = omit(rest, ["size"]);
  return (
    <FormField label={label} id={id} error={error} helper={helper} required={required}>
      <InputText
        id={id}
        disabled={disabled}
        error={error}
        value={value || ""}
        type={type || "text"}
        onChange={onChange}
        autoComplete={autoComplete || "disabled"}
        inputMode={inputMode}
        pattern={pattern}
        {...newRest}
      />
    </FormField>
  );
};

export const TextAreaField = ({
  id,
  label,
  required,
  error,
  helper,
  disabled,
  value,
  onChange,
  placeholder,
}: FormFieldProps) => (
  <FormField label={label} id={id} error={error} helper={helper} required={required}>
    <TextArea
      autoComplete="disabled"
      id={id}
      disabled={disabled}
      error={error}
      value={value}
      onChange={e => onChange && onChange(e.target.value)}
      placeholder={placeholder || ""}
    />
  </FormField>
);

type DateFieldProps = Omit<FormFieldProps, "value"> & {
  value: DateTime | null;
  dateFormat?: string;
};

export const DateField = ({
  id,
  label,
  required,
  error,
  helper,
  value,
  onChange,
  placeholder,

  dateFormat,
}: DateFieldProps) => (
  <FormField label={label} id={id} error={error} helper={helper} required={required}>
    <InputDatePicker
      id={id}
      onChange={onChange}
      value={value}
      error={error || undefined}
      placeholder={placeholder}
      dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
    />
  </FormField>
);

type Size = "sm" | "base";

type SelectFieldProps = FormFieldProps & {
  options: { value: string | number; name: string; disabled?: boolean }[];
  makeRef?: any;
  style?: {};
  size?: Size;
  fullWidth?: boolean;
};

export const SelectField = ({
  id,
  label,
  required,
  error,
  helper,
  disabled,
  value,
  options,
  onChange,
  makeRef,
  autoComplete,
  size,
  fullWidth,
}: SelectFieldProps) => {
  return (
    <FormField label={label} id={id} error={error} helper={helper} required={required}>
      <InputSelect
        autoComplete={autoComplete || "disabled"}
        onChange={(...args) => onChange && onChange(...args)}
        disabled={disabled}
        options={options}
        value={value}
        makeRef={makeRef}
        error={error}
        size={size}
        fullWidth={fullWidth}
      />
    </FormField>
  );
};
