import { currentLegalMode } from "hassibot/services_v2";
import { LegalMode } from "hassibot/services_v2/common/types";
import i18next from "i18next";
import * as frContractor from "./fr/contractor.json";
import * as frMandatary from "./fr/mandatary.json";
import { i18nNamespaceEnum } from "./types";

export const namespace =
  currentLegalMode === LegalMode.CONTRACTOR
    ? i18nNamespaceEnum.contractor
    : i18nNamespaceEnum.mandatary;
export const frResources = { contractor: frContractor, mandatary: frMandatary };

i18next.init({
  resources: { fr: frResources },
  ns: namespace,
  lng: "fr",
});
