import { Gender } from "hassibot/services_v2/common/types";

export const handlePlural = (
  number: number,
  { plural, singular }: { plural: string; singular: string }
): string => (number <= 1 ? singular : plural);

export const handleGender = (
  genre: Gender,
  { masculine, feminine }: { masculine: string; feminine: string }
): string => (genre === "female" ? feminine : masculine);

const buildOhIntlFormater = (options: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat("fr-FR", options);

export const formatPriceToString = (
  euros: number | string | null | undefined,
  customOptions?: Omit<Intl.NumberFormatOptions, "style" | "currency">
) => {
  if (euros === null || euros === undefined || Number.isNaN(euros)) {
    return "";
  } else {
    return buildOhIntlFormater({
      style: "currency",
      currency: "EUR",
      ...customOptions,
    })
      .format(Number(euros))
      .replace(/ /g, "\u00a0");
  }
};

export const formatPercentToString = (
  percentage: number | string | null | undefined,
  customConfig: Intl.NumberFormatOptions = { maximumFractionDigits: 2 }
) => {
  if (percentage === null || percentage === undefined || Number.isNaN(Number(percentage))) {
    return "";
  } else {
    return Intl.NumberFormat("fr-FR", {
      style: "percent",
      ...customConfig,
    }).format(Number(percentage));
  }
};

export const formatValueToDecimalString = (value: number | string | null | undefined) => {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return "";
  } else {
    return buildOhIntlFormater({
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(Number(value));
  }
};
