import { Agency, Zone } from "hassibot/services_v2/common/types";

// types
export type AgencyFilter = {
  _type: "agency";
  value: Agency;
};
export type ZoneFilter = {
  _type: "zone";
  value: Zone;
};
export type GeoFilter = AgencyFilter | ZoneFilter;

// factories
const zoneFilter = (z: Zone): ZoneFilter => ({
  _type: "zone",
  value: z,
});

export const agencyFilter = (s: Agency): AgencyFilter => ({
  _type: "agency",
  value: s,
});

// guards
export const isZoneFilter = (x: GeoFilter): x is ZoneFilter => "_type" in x && x._type === "zone";

export const isAgencyFilter = (x: GeoFilter): x is AgencyFilter =>
  "_type" in x && x._type === "agency";

const stableSort = (a: GeoFilter, b: GeoFilter) => {
  if (isZoneFilter(a) && isAgencyFilter(b)) return -1;
  else if (isZoneFilter(a) && isZoneFilter(b)) return a.value.slug.localeCompare(b.value.slug);
  else if (isAgencyFilter(a) && isAgencyFilter(b)) return a.value.uuid.localeCompare(b.value.uuid);
  else if (isAgencyFilter(a) && isZoneFilter(b)) return 1;
  else return 0;
};

const _toString =
  (zw: (Zone) => string, aw: (gency) => string, separator: string) =>
  (xs: GeoFilter[]): string =>
    [...xs]
      .sort(stableSort)
      .map((x: GeoFilter): string => (isZoneFilter(x) ? zw(x.value) : aw(x.value)))
      .join(separator);

export const toString = (xs: GeoFilter[]): string =>
  _toString(
    (x: Zone) => x.slug,
    (x: Agency) => x.uuid,
    ","
  )(xs);

export const toHumanString = (xs: GeoFilter[]): string =>
  _toString(
    (x: Zone) => x.pretty,
    (x: Agency) => x.name,
    ", "
  )(xs);

export const parseGeoString = (
  geoString: string,
  zones: Zone[],
  agencies: Agency[]
): GeoFilter[] => {
  const rawGeoItems = geoString.split(",");
  return rawGeoItems.reduce((acc, rawGeoItem) => {
    const zoneMatch = zones.find(z => z.slug === rawGeoItem);
    const agencyMatch = agencies.find(s => s.uuid === rawGeoItem);
    if (zoneMatch) {
      return acc.concat([zoneFilter(zoneMatch)]);
    } else if (agencyMatch) {
      return acc.concat([agencyFilter(agencyMatch)]);
    } else return acc;
  }, [] as GeoFilter[]);
};
