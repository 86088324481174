import { ValueOf } from "types";
import { CaregiverUuid, ProclientUuid } from "../common/types";

export const channelType = {
  CAREGIVER_ROOMS: "caregiver_rooms",
  PROCLIENT_GROUP_ROOMS: "proclient_group_rooms",
  AGENCY_GROUP_ROOMS: "agency_group_rooms",
} as const;

export type ChannelType = ValueOf<typeof channelType>;

export type CreateProclientGroupData = {
  type: typeof channelType.PROCLIENT_GROUP_ROOMS;
  proclientUuid: ProclientUuid | null;
  caregiverUuids: CaregiverUuid[];
};

export type EditProclientGroupData = {
  caregiverUuids: CaregiverUuid[];
};

export type EditAgencyGroupData = {
  caregiverUuids: CaregiverUuid[];
};
