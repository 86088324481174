import styled, { css } from "styled-components";
import { rem } from "polished";

import { InputSelect } from "emoreg/atoms/Inputs/InputSelect";
import { useModalApi } from "hassibot/component/modal";
import { AgencyFilterModal } from "hassibot/component/modals/agency-filter-modal";
import { useAppContext } from "hassibot/context";
import {
  GeoFilter,
  isAgencyFilter,
  isZoneFilter,
  agencyFilter,
  toString,
  parseGeoString,
} from "./types";

const WrapperSelect = styled.div`
  ${props =>
    props.fullWidth
      ? css`
          & > select {
            width: 100%;
          }
        `
      : css`
          & > select {
            width: ${rem(140)};
          }
        `}
`;

type SelectFilterSectorProps = {
  value?: string;
  onChange: (value?: string, valueObject?: GeoFilter[]) => void;
  style?: React.CSSProperties;
  className?: string;
  fullWidth?: boolean;
};

export const SelectGeographyFilter = ({ value, fullWidth, onChange }: SelectFilterSectorProps) => {
  const { myAgencies, zones, agencies } = useAppContext();
  const modalApi = useModalApi();

  const currentGeoFilters = value ? parseGeoString(value, zones, agencies) : undefined;

  const _onChange = newSector => {
    if (newSector === "__choice__") {
      modalApi
        .showModal<{ result: GeoFilter[] }>(props => (
          <AgencyFilterModal
            {...props}
            previouslySelectedAgencies={
              currentGeoFilters && !isSingleZone(currentGeoFilters)
                ? currentGeoFilters.filter(isAgencyFilter)
                : []
            }
            onValidate={selectedAgencies => props.resolver({ result: selectedAgencies })}
          />
        ))
        .then((resp?) => {
          modalApi.hideModal();
          if (resp && resp.result) {
            onChange(toString(resp.result), resp.result);
          }
        });
    } else {
      const newValue = parseGeoString(newSector, zones, agencies);
      onChange(toString(newValue), newValue);
    }
  };
  const isSingleZone = (geoitems: GeoFilter[]) =>
    geoitems.length === 1 && geoitems[0] && isZoneFilter(geoitems[0]);

  const isSingleAgency = (geoitems: GeoFilter[]) =>
    geoitems.length === 1 && geoitems[0] && isAgencyFilter(geoitems[0]);

  const isMyAgencies = (geoItems: GeoFilter[]) =>
    geoItems.every(s => isAgencyFilter(s) && myAgencies.some(sec => sec.uuid === s.value.uuid)) &&
    myAgencies.length === geoItems.length;

  const allMyAgencies = myAgencies.map(agency => agencyFilter(agency));

  const stringCurrentGeoFilters = currentGeoFilters ? toString(currentGeoFilters) : "";
  return (
    <WrapperSelect fullWidth={fullWidth}>
      <InputSelect
        options={[
          { label: "Toutes", value: "__default__" },
          ...[
            { label: "Choisir des agences...", value: "__choice__" },
            ...[
              ...(currentGeoFilters &&
              !isSingleZone(currentGeoFilters) &&
              !isMyAgencies(currentGeoFilters) &&
              !(isMyAgencies(currentGeoFilters) && isSingleAgency(currentGeoFilters))
                ? [
                    {
                      group: "Agences selectionnées",
                      label: currentGeoFilters
                        .map(geoFilter =>
                          isZoneFilter(geoFilter) ? geoFilter.value.pretty : geoFilter.value.name
                        )
                        .join(","),
                      value: stringCurrentGeoFilters,
                      disabled: true,
                    },
                  ]
                : []),
            ],
          ],
          ...[
            ...(myAgencies?.length > 0
              ? [
                  { label: "Toutes mes agences", value: toString(allMyAgencies) },
                  ...myAgencies.map(({ name, uuid }) => ({
                    value: uuid,
                    label: name,
                  })),
                ]
              : []),
          ],
          ...zones.map(({ pretty, slug }) => ({
            group: "Zones",
            value: slug,
            label: pretty,
          })),
        ]}
        value={currentGeoFilters ? stringCurrentGeoFilters : "__default__"}
        onChange={value => _onChange(value)}
      />
    </WrapperSelect>
  );
};

export * from "./types";
