import { instance } from "amplitude";
import { useSessionCollaboratorContext } from "inside-components";
import React, { useContext, useMemo } from "react";
import { GetProps, Matching } from "../types";
import { makePermerRequirements, PermerRequirements } from "./permer";
import {
  CachedData,
  Collaborator,
  CollaboratorDsitUuid,
  Mail,
  StaticData,
} from "./services_v2/common/types";
import { SessionCollaborator } from "./services_v2/login/types";
import { MainCacheKeys } from "./services_v2/static";

export type AppContextData = StaticData & CachedData;

export type AppContextType = {
  data: AppContextData;
  refreshAppContextKeys: (keys: MainCacheKeys[]) => void;
};

export const AppContext = React.createContext<AppContextType | null>(null);

// Default merge function namespaces the appContext in wrapped component's props
const defaultMerge = (appCtx, ownProps) => ({
  ...ownProps,
  ...appCtx,
});

/**
  @Deprecated see {@link useAppContext}

  Usage :
    const Component = (props) => <></>
    const selector = (ctx, ownProps) => ({zones: ctx.zones, ...ownProps})
    const ComponentAndContext = withAppContext(selector)(Component)
 */
export const withAppContext =
  <MappedProps, OwnProps>(
    mapProps: (ctx: AppContextData, ownProps: OwnProps) => MappedProps = defaultMerge
  ) =>
  <C extends React.ComponentType<Matching<MappedProps, GetProps<C>>>>(
    WrappedComponent: C
  ): React.ComponentType<
    JSX.LibraryManagedAttributes<C, Omit<GetProps<C>, keyof MappedProps>> & OwnProps
  > => {
    return (props: any) => (
      <AppContext.Consumer>
        {(appCtx: AppContextType | null) => {
          if (!appCtx)
            throw new Error(
              "The application context is missing, add 'AppContext.Provider' at the top of component hierarchy"
            );
          const newProps = mapProps(appCtx.data, props);
          return <WrappedComponent {...props} {...newProps} />;
        }}
      </AppContext.Consumer>
    );
  };

export const useAppContext = () => {
  const appCtxOpt = useContext(AppContext);
  if (!appCtxOpt) {
    throw new Error(
      "The application context is missing, add 'AppContext.Provider' at the top of component hierarchy"
    );
  }

  return appCtxOpt.data;
};

export const useRefreshAppContext = () => {
  const appCtxOpt = useContext(AppContext);
  if (!appCtxOpt) {
    throw new Error(
      "The application context is missing, add 'AppContext.Provider' at the top of component hierarchy"
    );
  }

  return appCtxOpt.refreshAppContextKeys;
};

export type NameableCollaborator = Collaborator & {
  name: string;
};

export type NameableSessionCollaborator = SessionCollaborator & {
  name: string;
  permer: PermerRequirements;
};

export const useCurrentCollaborator = (): NameableSessionCollaborator => {
  const sessionCollaborator = useSessionCollaboratorContext();
  const { permer } = useAppContext();
  // Ensure that a "stable" reference is returned by this hook.
  const rv = useMemo(
    () => ({
      ...sessionCollaborator,
      name: `${sessionCollaborator.firstName} ${sessionCollaborator.lastName}`,
      permer: makePermerRequirements(
        sessionCollaborator.permerUserAttributes,
        permer.securityPolicy
      ),
    }),
    [sessionCollaborator, permer.securityPolicy]
  );

  return rv;
};

export const resolveCollaborator = (
  identifier: string | CollaboratorDsitUuid | null,
  collaborators: Collaborator[]
): Collaborator | null => {
  const resolverMethods = [
    (c: Collaborator) => c.mail === identifier,
    (c: Collaborator) => c.dsitUuid === identifier,
  ];
  return collaborators.find(c => resolverMethods.some(r => r(c))) || null;
};

export const useCollaborator = (
  identifier: string | CollaboratorDsitUuid | null
): NameableCollaborator | null => {
  const { myAgencies, collaborators } = useAppContext();
  if (!identifier) {
    return null;
  }
  // Faire en sorte que les infos de l'agence soit retourné dans /start-session
  instance.setUserProperties({ Agency_Slug: myAgencies.map(a => a.name) });

  // try to resolve the identity of the collaborator
  const collaborator = resolveCollaborator(identifier, collaborators);
  return collaborator
    ? {
        ...collaborator,
        name: `${collaborator.firstName} ${collaborator.lastName}`,
      }
    : null;
};

export const useCollaborators = (): Collaborator[] => {
  const { collaborators } = useAppContext();
  return collaborators;
};

export const useCollaboratorByMailOrUuid = (value?: Mail | CollaboratorDsitUuid | null) => {
  const { collaborators } = useAppContext();
  return collaborators.find(c => c.mail === value || c.dsitUuid === value) || null;
};
