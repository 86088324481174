import { channelType } from "hassibot/services_v2/chat/types";
import { ValueOf } from "types";

export const chatDisplayMode = {
  OVERLAY: "overlay",
  PAGE: "page",
} as const;
export type ChatDisplayMode = ValueOf<typeof chatDisplayMode>;

export const chatReadFilter = {
  ALL: "all",
  UNREAD: "unread",
} as const;
export type ChatReadFilter = ValueOf<typeof chatReadFilter>;
export const isChatReadFilter = (value: string): value is ChatReadFilter =>
  Object.values(chatReadFilter).includes(value as any);

export const chatChannelTypeFilter = {
  ALL: "all",
  CAREGIVERS: "caregivers",
  PROCLIENTS: "proclients",
  AGENCIES: "agencies",
} as const;

export type ChatChannelTypeFilter = ValueOf<typeof chatChannelTypeFilter>;
export const isChatChannelTypeFilter = (value: string): value is ChatChannelTypeFilter =>
  Object.values(chatChannelTypeFilter).includes(value as any);

export const MAP_OH_FILTER_TO_STREAM_FILTER = {
  [chatChannelTypeFilter.ALL]: Object.values(channelType),
  [chatChannelTypeFilter.CAREGIVERS]: [channelType.CAREGIVER_ROOMS],
  [chatChannelTypeFilter.PROCLIENTS]: [channelType.PROCLIENT_GROUP_ROOMS],
  [chatChannelTypeFilter.AGENCIES]: [channelType.AGENCY_GROUP_ROOMS],
};

export const CHANNEL_ID_URL_PARAM = "channel-id";

export const OVERLAY_ACTIVE_CHANNEL_ID = "chat-overlay-channel-id";
export const READ_FILTER_KEY = "chat-read-filter";
export const CHANNEL_TYPE_FILTER_KEY = "chat-channel-type-filter";

export const POLLING_RATE_SECONDS = 10;
