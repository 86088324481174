import { ChangeEvent } from "react";
import styled, { css } from "styled-components";
import { Label } from "styles/atoms";
import { StyledLabel } from "styles/atoms/label";

import { spaces } from "emoreg/const";
import { rem } from "polished";
import { responsiveHelpers as rh } from "styles/utils";
import { FieldErrors } from "./form-items";

const InputLabeledForm = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spaces[4]};
  ${StyledLabel} {
    padding-left: ${spaces[8]};
  }
  ${props =>
    props.disabled &&
    css`
      cursor: inherit;
    `}
  & > * {
    margin-bottom: 0;
    cursor: pointer;
  }
  ${rh.belowMobile`
    padding: ${rem(10)} 0;
  `}
`;

type CheckboxProps = {
  checked?: boolean;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  style?: {};
  disabled?: boolean;
  error?: string | string[] | JSX.Element;
};

/**
 * @deprecated
 */
const CheckboxLabeled = ({
  checked,
  label,
  onChange,
  id,
  style,
  disabled,
  error,
}: CheckboxProps) => (
  <InputLabeledForm style={style} disabled={disabled}>
    <input
      type="checkbox"
      id={id}
      onChange={onChange}
      disabled={disabled ? disabled : false}
      checked={checked || false}
    />
    <Label noBold={!checked && true} htmlFor={id} disabled={disabled}>
      {label}
    </Label>
    {error && <FieldErrors error={error}></FieldErrors>}
  </InputLabeledForm>
);

type RadioProps = {
  checked?: boolean;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
};

const RadioLabeled = ({ checked, label, onChange, id, name, disabled }: RadioProps) => (
  <InputLabeledForm>
    <input
      type="radio"
      id={id}
      onChange={onChange}
      checked={checked}
      {...(name && { name })}
      disabled={disabled}
    />
    <Label noBold htmlFor={id}>
      {label}
    </Label>
  </InputLabeledForm>
);

export { CheckboxLabeled, RadioLabeled };
