import { color, font } from "emoreg/const";
import { Flex } from "emoreg";
import { rem } from "polished";
import React, { useState } from "react";
import styled from "styled-components";

type LabelActionsProps = {
  children: string;
  onClick: () => void;
  color?: string;
};

const LabelActionsStyle = styled.span`
  cursor: pointer;
  font-size: ${rem(12)};
  font-weight: ${font.weight.semiBold};
  color: ${props => props.color || color.grey.dark};
  &:hover {
    text-decoration: underline;
  }
`;

export const LabelAction = (props: LabelActionsProps) => {
  return (
    <LabelActionsStyle onClick={props.onClick} color={props.color}>
      {props.children}
    </LabelActionsStyle>
  );
};

const asyncCopyWithFallback = (text: string | undefined) =>
  new Promise((resolve, reject) => {
    if (text === undefined) {
      reject();
    } else {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(resolve);
      } else {
        //fallback behavior
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          const successful = document.execCommand("copy");
          successful ? resolve(text) : reject();
        } catch (err) {
          reject(err);
        } finally {
          document.body.removeChild(textArea);
        }
      }
    }
  });

export const LabelActionCopy = ({
  getContentToCopy,
}: {
  getContentToCopy: () => string | undefined;
}) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    asyncCopyWithFallback(getContentToCopy()).then(
      () => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      },
      err => console.error("Could not write to clipboard", err)
    );
  };

  return <LabelAction onClick={copy}>{copied ? "Copié ✓" : "Copier"}</LabelAction>;
};

const LabelStyle = styled.span`
  font-size: ${rem(12)};
  color: ${color.grey.dark};
  font-weight: ${font.weight.normal};
`;

const RequiredStyle = styled.span`
  color: ${color.red.base};
`;

type LabelProps = {
  children: string;
  required?: boolean;
  // If you're using this prop, look for `LabelAction`.
  actionComponents?: ((key: React.Key) => JSX.Element)[];
};

export const Label = (props: LabelProps) => (
  <Flex gap={8}>
    <LabelStyle>
      {props.children} {props.required ? <RequiredStyle>*</RequiredStyle> : null}
    </LabelStyle>
    {props.actionComponents && props.actionComponents.length > 0 && (
      <Flex gap={4}>
        {props.actionComponents.map((actionComponent, index) => (
          <React.Fragment key={index}>
            {index !== 0 ? <LabelStyle>•</LabelStyle> : null}
            {actionComponent(index)}
          </React.Fragment>
        ))}
      </Flex>
    )}
  </Flex>
);
