import { PropsWithChildren } from "react";
import styled from "styled-components";
import { rem } from "polished";
import { responsiveHelpers as rh } from "styles/utils";
import { spaces } from "emoreg/const";
import { ModalHeader, ModalFooter } from "styles/molecules";

const Body = styled.div`
  background: #fff;
  border-radius: ${rem(4)};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  ${rh.belowMobile`
    position: relative;
    margin: 0;
    top: 0;
    transform: translate(0, 0);
    height: 100vh;
  `}
`;

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background: #fff;
  background-clip: padding-box;
  border-radius: ${rem(4)};
  outline: 0;
`;

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: ${spaces[24]};
`;

type ModalSubtitleStyle = Pick<React.CSSProperties, "color" | "fontWeight">;

type ModalProps = {
  id?: string;
  title: string | JSX.Element;
  surtitle?: string;
  subtitle?: string | JSX.Element;
  subtitleStyle?: ModalSubtitleStyle;
  footer?: JSX.Element;
  style?: React.CSSProperties;
};

const GenericModal = (props: PropsWithChildren<ModalProps> & { maxWidth: number }) => (
  <Body maxWidth={props.maxWidth}>
    <ModalWrapper>
      <ModalHeader
        subtitle={props.subtitle}
        subtitleStyle={props.subtitleStyle}
        title={props.title}
        surtitle={props.surtitle}
      />
      <ModalBody id={props.id} style={props.style}>
        {props.children}
      </ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
    </ModalWrapper>
  </Body>
);

export const Modal = (props: PropsWithChildren<ModalProps>) => (
  <GenericModal {...props} maxWidth={540} />
);

export const LargeModal = (props: PropsWithChildren<ModalProps>) => (
  <GenericModal {...props} maxWidth={900} />
);
