import { DeviceId } from "hassibot/services_v2/common/types";

class AppConfig {
  API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
  ASYNC_API_BASE_URL = import.meta.env.VITE_ASYNC_API_BASE_URL;
  API_WS_BASE_URL = import.meta.env.VITE_API_WS_BASE_URL;
  WWW_BASE_URL = import.meta.env.VITE_WWW_BASE_URL;
  CUSTOMER_SERVICE_BASE_URL = import.meta.env.VITE_CUSTOMER_SERVICE_BASE_URL;
  XIMI_BASE_URL = import.meta.env.VITE_XIMI_BASE_URL;
  ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID;
  ALGOLIA_API_KEY = import.meta.env.VITE_ALGOLIA_API_KEY;
  ALGOLIA_APP_INDEX = import.meta.env.VITE_ALGOLIA_APP_INDEX;
  IS_TEST_ENVS = !!import.meta.env.VITE_IS_TEST_ENVS;
  IS_DEV = !!import.meta.env.VITE_IS_DEV;
  BUILD_HASH = import.meta.env.VITE_BUILD_HASH || `dev-${Math.random().toString(36).substring(3)}`;
  SW_BUILD_HASH: string | null = null;
  DEVICE_ID: DeviceId | null = null;
  SCREEB_APP_ID: string | null = null;
  STREAM_CHAT_API_KEY: string = import.meta.env.VITE_STREAM_CHAT_API_KEY;
  IS_XIMI_MIGRATION_WARNING = import.meta.env.VITE_XIMI_MIGRATION_WARNING === "true";
  IS_XIMI_MIGRATION_ONGOING = import.meta.env.VITE_XIMI_MIGRATION_ONGOING === "true";
  IS_MAINTENANCE_MODE = import.meta.env.VITE_MAINTENANCE_MODE === "true";
}

const appConfig = new AppConfig();

export default appConfig;
