import React from "react";
import styled from "styled-components";

import { spaces } from "emoreg/const";

import { Emoji, Title, Label } from "styles/atoms";

const EmptyStateStyle = styled.div`
  padding: ${spaces[32]};
  text-align: center;
`;

export const EmptyState = ({ title, subTitle, icon }) => {
  return (
    <EmptyStateStyle>
      <Emoji style={{ fontSize: spaces[32], marginBottom: spaces[16] }} name={icon} />
      {title && <Title type="h4">{title}</Title>}
      <Label>{subTitle}</Label>
    </EmptyStateStyle>
  );
};
