import { color } from "emoreg/const";
import { css } from "styled-components";

export const focusableInputStyle = css`
  appearance: none;
  &:focus-visible,
  &:focus,
  &:focus-within {
    outline: 2px solid ${color.blue.base};
  }
`;
