import { color, radius, spaces } from "emoreg/const";
import styled from "styled-components";

const Container = styled.div<{ hasError?: boolean }>(
  {
    display: "inline-block",
    position: "relative",
    height: spaces[16],
    width: spaces[16],
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: radius.base,
    pointerEvents: "none",

    "&:before": {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      fontSize: "10px",
      transform: "translate(-50%, -50%)",
    },

    // only use css feature to detect if input is checked
    // allowing uncontrolled component to work as well
    // as controlled components
    "&:has(input:checked)": {
      backgroundColor: color.blue.base,
      "&:before": {
        content: "'✔'",
      },
    },

    "&:has(input:indeterminate)": {
      backgroundColor: color.blue.base,
      "&:before": {
        content: "'-'",
        fontWeight: "bold",
      },
    },

    "&:has(input:disabled)": {
      backgroundColor: color.grey.light,
    },
  },
  ({ hasError }) => ({
    borderColor: hasError ? color.red.base : color.grey.base,
  })
);

Container.baseName = "CheckboxInputContainer";

const Input = styled.input({
  position: "absolute",
  inset: 0,
  pointerEvents: "all",
  cursor: "pointer",
  opacity: 0,
  "&:disabled": {
    cursor: "not-allowed",
  },
});

Input.baseName = "CheckboxInput";

export const CheckboxInputStyle = Object.assign(Container, { Input });
