import { PropsWithChildren } from "react";
import { color } from "emoreg/const";
import { Typography } from "emoreg/atoms/Typography";
import styled from "styled-components";

const typeToColor = {
  error: color.red.base,
  warning: color.orange.dark,
  help: color.grey.dark,
};

const MultiLineTypography = styled(Typography)({
  whiteSpace: "pre-line",
});

export const InputContextText = ({
  type,
  children,
}: PropsWithChildren<{
  type: keyof typeof typeToColor;
}>) => (
  <MultiLineTypography color={typeToColor[type]} size={"small"}>
    {children}
  </MultiLineTypography>
);
