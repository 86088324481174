import React from "react";
import styled from "styled-components";
import { ifProp, switchProp } from "styled-tools";

import { radius } from "styles/const";
import { color, spaces } from "emoreg/const";
import DropDownIcon from "assets/icons/drop-down.svg";

const Inner = styled.select`
  appearance: none;
  background: #fff;
  border: none;
  ${ifProp("fullWidth", "width: 100%;")};
  padding: ${switchProp("size", {
    sm: `${spaces[8]} ${spaces[32]} ${spaces[8]} ${spaces[16]}`,
    base: `${spaces[12]} ${spaces[32]} ${spaces[12]} ${spaces[16]}`,
  })};
  border-radius: ${radius.base};
  border: 1px solid ${color.grey.base};
  background: url(${DropDownIcon}) no-repeat right white;
  background-position-x: 98%;
  cursor: pointer;
  ${ifProp("error", `border-color: ${color.red.base};`)}
  &:disabled {
    color: ${color.grey.dark};
    background-color: ${color.grey.light};
    cursor: not-allowed;
  }
`;

type Option = { value: string | number | JSX.Element | null; name: string; disabled?: boolean };
type GroupedOption = { groupName: string; options: Option[] };

const isGroupedOption = (o: SelectOption): o is GroupedOption => "options" in o;

export type SelectOption = Option | GroupedOption;

type InputSelectProps = {
  onChange: (value: any) => void;
  options: SelectOption[];
  value: string | null | undefined;
  size?: "sm" | "base";
  className?: string;
  makeRef?: any;
  style?: {};
  disabled?: boolean;
  fullWidth?: boolean;
  error?: string[] | string | JSX.Element | null;
  autoComplete?: string;
};

/** @deprecated Use InputSelect from emoreg instead.*/
export const InputSelect = ({
  size = "sm",
  onChange,
  makeRef,
  options,
  value,
  disabled,
  style,
  fullWidth,
  error,
  ...rest
}: InputSelectProps) => {
  const renderOption = (o: Option, i: number) =>
    o ? (
      React.isValidElement(o.value) ? (
        o.value
      ) : (
        <option value={typeof o.value == "string" ? o.value : ""} key={i} disabled={o.disabled}>
          {o.name}
        </option>
      )
    ) : null;

  return (
    <Inner
      disabled={disabled}
      onChange={e => onChange(e.target.value)}
      ref={makeRef}
      value={value}
      style={style}
      size={size}
      fullWidth={fullWidth}
      error={error}
      {...rest}
    >
      {options?.map((o, i) =>
        isGroupedOption(o) ? (
          <optgroup key={i} label={o.groupName} title={o.groupName}>
            {o.options.map(renderOption)}
          </optgroup>
        ) : (
          renderOption(o, i)
        )
      )}
    </Inner>
  );
};
