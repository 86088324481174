import styled from "styled-components";
import { color, spaces } from "emoreg/const";

const ZoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: ${spaces[24]};
`;
ZoneContainer.displayName = "ZoneContainer";

export const BatchSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${spaces[24]};
`;
BatchSelectorContainer.displayName = "BatchSelectorContainer";

export const ClickableText = styled.p`
  color: ${color.grey.dark};
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;
ClickableText.displayName = "ClickableText";
