import React from "react";
import styled from "styled-components";

import { responsiveHelpers as rh } from "styles/utils";
import { device } from "styles/const";
import { Subtract } from "types";
import { useContext, useEffect, useCallback, useMemo } from "react";

export type GenericModalInjectedProps<T> = {
  resolver: (value?: T) => void;
  hideModal: () => void;
};

type ModalCreator<T> = (props: GenericModalInjectedProps<T>) => JSX.Element;
type ModalOptions = { hideModalOnClickOutside?: boolean };

type ModalApiType = {
  showModal: <T>(creator: ModalCreator<T>, options?: ModalOptions) => Promise<T | undefined>;
  modalShown: JSX.Element | null;
  hideModal: () => void;
};

const ModalContext = React.createContext<ModalApiType>({
  showModal: () => Promise.resolve(undefined),
  modalShown: null,
  hideModal: () => {},
});

type ModalManagerStateType = {
  modalShown: JSX.Element | null;
  modalOptions: ModalOptions | null;
  unlockScroll?: () => void;
};

type ModalManagerProps = {
  children: JSX.Element | null;
};

class ModalManager extends React.Component<ModalManagerProps, ModalManagerStateType> {
  constructor(props: ModalManagerProps) {
    super(props);
    this.state = {
      modalShown: null,
      modalOptions: null,
    };
  }

  showModal = (
    modalCreator: (ModalInjectedProps) => JSX.Element,
    modalOptions: ModalOptions = { hideModalOnClickOutside: true }
  ) => {
    return new Promise<undefined>(resolver => {
      // here is the key part :
      // we inject resolver and hideModal so we can respectively
      // resolve a result from inside the modal and hide the modal.

      const m = modalCreator({ resolver, hideModal: this.hideModal });
      this.setState({
        modalShown: m,
        modalOptions,
      });
    });
  };

  hideModal = () => {
    this.setState((currentState: ModalManagerStateType) => {
      if (currentState.modalShown) {
        const { resolver } = currentState.modalShown.props;
        resolver && resolver();
      }
      return { modalShown: null, modalOptions: null, unlockScroll: undefined };
    });
  };

  render() {
    return (
      <ModalContext.Provider
        value={{
          showModal: this.showModal,
          modalShown: this.state.modalShown,
          hideModal: this.hideModal,
        }}
      >
        <>
          {this.state.modalShown ? (
            <ModalContainer
              hideModal={
                this.state.modalOptions?.hideModalOnClickOutside ? this.hideModal : () => {}
              }
            >
              {this.state.modalShown}
            </ModalContainer>
          ) : null}
          {this.props.children}
        </>
      </ModalContext.Provider>
    );
  }
}

const Box = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  ${rh.belowMobile`
    background: white;
  `}
`;

type ModalContainerProps = {
  hideModal: () => void;
  children: JSX.Element;
};

const ModalContainer = (props: ModalContainerProps) => {
  const escapeBinding = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "27") {
        props.hideModal();
      }
    },
    [props]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("key", handleResize);
    document.addEventListener("keyup", escapeBinding);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("keyup", escapeBinding);
    };
  }, [escapeBinding]);

  const handleResize = () => {
    if (window.innerWidth > device.portraitTablet) {
      return;
    }
  };

  return (
    <Box onClick={props.hideModal}>
      <div style={{ height: "100vh" }} onClick={e => e.stopPropagation()}>
        {props.children}
      </div>
    </Box>
  );
};

export type ModalApiProps = {
  modalApi: ModalApiType;
};

/**
 *
 * @deprecated prefer to use the hook {@link useModalApi}
 */
const withModalApi =
  <T extends ModalApiProps>(Component: React.ComponentType<T>) =>
  (props: Subtract<T, ModalApiProps>) => {
    return (
      <ModalContext.Consumer>
        {modalProps => <Component {...(props as T)} modalApi={modalProps} />}
      </ModalContext.Consumer>
    );
  };

const useModalApi = () => {
  const { hideModal, showModal } = useContext(ModalContext);
  return useMemo(() => {
    return { hideModal, showModal };
  }, [hideModal, showModal]);
};

export { ModalManager, withModalApi, useModalApi };
